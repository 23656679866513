import React, { useState, useEffect, useContext } from "react";
import GetApiUrlContext from "../../context/GetApiUrlContext";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import DatePicker, { registerLocale } from "react-datepicker";
import en from "date-fns/locale/en-US";
import he from "date-fns/locale/he";
import { react_select_custom_style } from "../../config/customStyles";
import Select from "react-select";
import { convertToLocalDate } from "../../utils/convertToLocalDate";
import "react-datepicker/dist/react-datepicker.css";
import AuthContext from "../../context/AuthContext";
import useAxios from "../../utils/useAxios";
import TicketsTable from "./ticketsTable";
import Pagination from "../common/pagination";
import { paginate } from "../../utils/paginate";
import _ from "lodash";
import Navbar from "../navbar";
import axios from "axios";
import Button from "../common/button";

// import axiosAuth from "../utils/useAxios"

registerLocale("he", he);
registerLocale("en", en);

const Tickets = () => {
  const apiUrl = useContext(GetApiUrlContext);

  let params = useParams();

  const { t } = useTranslation();

  let { user } = useContext(AuthContext);

  let filterObj = {
    category: "",
    status: "",
    support: "",
    customer: "",
    start: "",
    end: "",
  };
  let [tickets, setTickets] = useState([]);
  let [showingPrivates, setShowingPrivates] = useState(false);
  let [clientFilter, setClientFilter] = useState({});
  let [categories, setCategories] = useState([]);
  let [statuses, setStatuses] = useState([]);
  let [customers, setCustomers] = useState([]);
  let [employees, setEmployees] = useState([]);
  let [search, setSearch] = useState("");
  let [pageProps, setPageProps] = useState({ currentPage: 1, pageSize: 10 });
  let [sortColumn, setSortColumn] = useState({ path: "id", order: "desc" });
  let [start, setStart] = useState();
  let [end, setEnd] = useState();
  let [category, setCategory] = useState({
    name: <span className="text-muted">{t("category")}</span>,
    local_name: <span className="text-muted">{t("category")}</span>,
  });
  let [status, setStatus] = useState({
    name: <span className="text-muted">{t("status")}</span>,
    local_name: <span className="text-muted">{t("status")}</span>,
  });
  let [employee, setEmployee] = useState({
    name: <span className="text-muted">{t("employee")}</span>,
  });
  let [customer, setCustomer] = useState({
    name: <span className="text-muted">{t("customer")}</span>,
  });

  let axiosAuth = useAxios();

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     loadData();
  //   }, 1000);
  //   return () => clearInterval(interval);
  // }, []);

  let currentComapny;

  const handleClientChange = (e) => {
    setClientFilter((values) => ({
      ...values,
      [e.target.name]: e.target.value,
    }));
  };

  let getCategories = async () => {
    if (localStorage.getItem("currentCompany")) {
      currentComapny = JSON.parse(localStorage.getItem("currentCompany"));
    }
    let response = await axios.get(`${apiUrl}/categories/${currentComapny.id}`);
    if (response.status === 200) {
      if (i18next.language === "en") {
        response.data = _.sortBy(response.data, "name");
      } else {
        response.data = _.sortBy(response.data, "local_name");
      }
      setCategories(response.data);
    }
  };

  let getStatuses = async () => {
    let response = await axiosAuth.get(
      `${apiUrl}/tickets/status_list/${currentComapny.id}`
    );
    if (response.status === 200) {
      if (i18next.language === "en") {
        response.data = _.sortBy(response.data, "name");
      } else {
        response.data = _.sortBy(response.data, "local_name");
      }
      setStatuses(response.data);
    }
  };

  let getCustomers = async () => {
    let response;
    if (user.role === "employee") {
      response = await axiosAuth.get(
        `${apiUrl}/customers/${currentComapny.id}`
      );
      if (response.status === 200) {
        response.data = _.sortBy(response.data, "name");
        setCustomers(response.data);
      }
    }
  };
  let getEmployees = async () => {
    if (user.role === "employee") {
      let response;
      response = await axiosAuth.get(
        `${apiUrl}/employees/${currentComapny.id}`
      );
      if (response.status === 200) {
        response.data = _.sortBy(response.data, "first_name");
        setEmployees(response.data);
      }
    }
  };

  let getTickets = async (customer, employee, status, category, start, end) => {
    if (localStorage.getItem("currentCompany")) {
      currentComapny = JSON.parse(localStorage.getItem("currentCompany"));
    }

    let connection = `/tickets/${currentComapny.id}/?`;

    // if (filter.category) connection += `category_id=${filter.category}`;
    // if (filter.status) connection += `status=${filter.status}`;

    if (category !== undefined && category !== null && category.id)
      connection += `&category=${category.id}`;
    if (status !== undefined && status !== null && status.id)
      connection += `&status=${status.id}`;
    if (employee !== undefined && employee !== null && employee.id)
      connection += `&employee=${employee.id}`;

    if (customer !== undefined && customer !== null && customer.id) {
      connection += `&customer=${customer.id}`;
      setCustomer(customer);
    }

    if (start !== undefined)
      connection += `&start=${convertToLocalDate(
        new Date(start).toLocaleDateString()
      )}`;
    if (end !== undefined)
      connection += `&end=${convertToLocalDate(
        new Date(end).toLocaleDateString()
      )}`;
    if (
      (category && category.id) ||
      (status && status.id) ||
      (employee && employee.id) ||
      (customer && customer.id) ||
      start ||
      end ||
      showingPrivates
    ) {
      connection += `&show-privates=${true}`;
    }

    let response = await axiosAuth.get(connection);
    if (response.status === 200) {
      setTickets(response.data);
    }
  };

  const getTicketWithParamsCheck = () => {
    if (params.customer_id === undefined) {
      getTickets();
    } else {
      let params_customer = {
        id: params.customer_id,
        name: params.customer_name,
        ...customer,
      };
      setCustomer(params_customer);
      getTickets(params_customer);
    }
  };

  // const useMountEffect = (getTickets) => useEffect(getTickets, []);

  // useMountEffect(getTickets);

  const useMountEffect = (getTicketWithParamsCheck) =>
    useEffect(getTicketWithParamsCheck, []);

  useMountEffect(getTicketWithParamsCheck);

  useEffect(() => {
    getCategories();
    getStatuses();
    getEmployees();
    getCustomers();

    const interval = setInterval(() => {
      if (
        !customer.id &&
        !employee.id &&
        !status.id &&
        !category.id &&
        start === undefined &&
        end === undefined
      ) {
        getTickets();
      }
    }, 10000);

    return () => {
      clearInterval(interval);
    };
  }, [customer, employee, status, category, start, end, showingPrivates]);
  // customer, employee, status, category, start, end;
  let clearForm = () => {
    setClientFilter(filterObj);
    setCategory({
      name: <span className="text-muted">{t("category")}</span>,
      local_name: <span className="text-muted">{t("category")}</span>,
    });

    setStatus({
      name: <span className="text-muted">{t("status")}</span>,
      local_name: <span className="text-muted">{t("status")}</span>,
    });
    setCustomer({
      name: <span className="text-muted">{t("customer")}</span>,
    });
    setEmployee({
      name: <span className="text-muted">{t("employee")}</span>,
    });
    setStart();
    setEnd();
    setSearch("");
    setShowingPrivates(false);

    getTickets();
  };

  const handleJumpTicket = async (ticketId, is_light) => {
    if (is_light === undefined || is_light === false) {
      is_light = true;
    } else {
      is_light = false;
    }

    currentComapny = JSON.parse(localStorage.getItem("currentCompany"));
    let connectionString = `${apiUrl}/tickets/${currentComapny.id}/${ticketId}/`;
    await axiosAuth.patch(connectionString, { is_light: is_light });
    getTickets();
  };
  let handleSubmit = async (e) => {
    e.preventDefault();
    getTickets(customer, employee, status, category, start, end);
  };
  // let handlePageChange = page => {
  //   this.setPageProps({ currentPage: page });
  // };

  let getPageData = (allTickets) => {
    let filtered = allTickets;

    if (search) {
      let filtered1 = filtered.filter((x) =>
        x.customer_name.toLowerCase().includes(search.toLowerCase())
      );
      let filtered2 = filtered.filter((x) =>
        x.full_name.toLowerCase().includes(search.toLowerCase())
      );
      let filtered3 = filtered.filter((x) =>
        x.id.toString().toLowerCase().includes(search.toLowerCase())
      );
      let filtered4 = filtered.filter((x) =>
        x.email.toLowerCase().includes(search.toLowerCase())
      );
      let filtered5 = filtered.filter((x) =>
        x.description.toLowerCase().includes(search.toLowerCase())
      );
      // let filtered5 = filtered.filter((x) =>
      //   x.category.name.toLowerCase().includes(search.toLowerCase())
      // );
      // let filtered6 = filtered.filter((x) =>
      //   x.status.toLowerCase().includes(search.toLowerCase())
      // );

      let filteredUniqSet = new Set([
        ...filtered1,
        ...filtered2,
        ...filtered3,
        ...filtered4,
        ...filtered5,
        // ...filtered6,
      ]);
      filtered = [...filteredUniqSet];
    }

    let sorted = _.orderBy(
      filtered,
      ["is_light", sortColumn.path],
      ["desc", sortColumn.order]
    );

    // sorted = sorted.sort()

    const tickets = paginate(sorted, pageProps.currentPage, pageProps.pageSize);

    return { totalCount: filtered.length, data: tickets };
  };

  const { totalCount, data: ticketsPaginated } = getPageData(tickets);

  return (
    <React.Fragment>
      <Navbar />

      <div className="container-xl mt-3 mb-3" style={{ maxWidth: 1600 }}>
        <div className="row" dir="ltr">
          {user && user.role === "employee" && (
            <div
              className="col-xl-1 small_center"
              style={{ textAlign: "right" }}
            >
              <h1 className="text-primary">{t("total")}</h1>

              <span
                id="countTickets"
                className={tickets.length < 6 ? "text-success" : "text-danger"}
                style={{ fontSize: 130, lineHeight: "90%" }}
              >
                {tickets.length}
              </span>
            </div>
          )}

          {/* <h3>Showing {totalCount} tickets in the database.</h3> */}
          <div
            className="col-xl-10"
            dir="ltr"
            style={
              user && user.role === "customer"
                ? { width: "75%", marginLeft: "auto", marginRight: "auto" }
                : {}
            }
          >
            <div className="row justify-content-center">
              <div className="col-xl-4 mb-2 align-self-center">
                <input
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                  className="form-control text-center search-input"
                  type="text"
                  placeholder={t("search_base_filter")}
                  value={search}
                ></input>
              </div>
              {user.role === "customer" && (
                <div className="col-xl-2">
                  <Button type="submit" label={t("filter_tickets")} />
                </div>
              )}
              {user.role === "employee" && (
                <div className="col-xl-2 small_center">
                  <label htmlFor="showPrivate" className="text-danger">
                    {t("show_hiddens")}{" "}
                  </label>
                  &nbsp;
                  <input
                    type="checkbox"
                    checked={showingPrivates}
                    value={showingPrivates}
                    onChange={() => {
                      setShowingPrivates(showingPrivates ? false : true);
                    }}
                    id="showPrivate"
                  />
                </div>
              )}
            </div>
            <form id="filterForm" onSubmit={handleSubmit}>
              <div className="row justify-content-center mt-3">
                <div className="col-xl-3 mb-3">
                  {/* <input className="form-control" placeholder="Start Date" /> */}
                  <DatePicker
                    locale={i18next.language}
                    dateFormat="dd/MM/yyyy"
                    placeholderText={t("start_date")}
                    className="form-control text-center"
                    selected={start}
                    onChange={(date) => setStart(date)}
                  />
                </div>
                <div className="col-xl-3 mb-3">
                  {/* <input className="form-control" placeholder="End Date" /> */}
                  <DatePicker
                    locale={i18next.language}
                    dateFormat="dd/MM/yyyy"
                    placeholderText={t("end_date")}
                    className="form-control text-center"
                    selected={end}
                    onChange={(date) => setEnd(date)}
                  />
                </div>
                <div className="col-xl-2 mb-2 d-grid d-xl-block">
                  <button
                    type="button"
                    id="cleanForm"
                    className="btn btn-outline-success"
                    onClick={clearForm}
                  >
                    {t("clear_fields")}
                  </button>
                </div>
              </div>
              <div className="row justify-content-center mt-2">
                {user.role === "employee" && (
                  <React.Fragment>
                    <div className="col-xl-2 mb-3">
                      <Select
                        className="text-center"
                        styles={react_select_custom_style}
                        options={categories}
                        value={category}
                        getOptionLabel={(options) => options[t("name")]}
                        getOptionValue={(options) => options["id"]}
                        onChange={setCategory}
                      />
                    </div>
                    <div className="col-xl-2 mb-3 ">
                      <Select
                        className="text-center"
                        styles={react_select_custom_style}
                        value={status}
                        options={statuses}
                        getOptionLabel={(options) => options[t("name")]}
                        getOptionValue={(options) => options["id"]}
                        onChange={setStatus}
                      />
                    </div>
                    <div className="col-xl-2 mb-3">
                      <Select
                        className="text-center"
                        styles={react_select_custom_style}
                        options={employees}
                        value={employee}
                        getOptionLabel={(options) => options["name"]}
                        getOptionValue={(options) => options["id"]}
                        onChange={setEmployee}
                      />
                    </div>
                    <div className="col-xl-2 mb-3">
                      <Select
                        className="text-center"
                        styles={react_select_custom_style}
                        options={customers}
                        value={customer}
                        getOptionLabel={(options) => options["name"]}
                        getOptionValue={(options) => options["id"]}
                        onChange={setCustomer}
                      />
                    </div>

                    <div className="col-xl-2 d-grid d-xl-block">
                      <Button type="submit" label={t("filter_tickets")} />
                    </div>
                  </React.Fragment>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
      <TicketsTable
        getTickets={getTickets}
        handleJumpTicket={handleJumpTicket}
        tickets={ticketsPaginated}
        sortColumn={sortColumn}
        onSort={(sortColumn) => setSortColumn(sortColumn)}
      />
      <Pagination
        itemsCount={totalCount}
        pageSize={pageProps.pageSize}
        currentPage={pageProps.currentPage}
        onPageChange={(page) => {
          setPageProps({ ...pageProps, currentPage: page });
        }}
      />
    </React.Fragment>
  );
};

export default Tickets;
