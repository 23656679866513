import { useTranslation } from "react-i18next";
import React, { useContext, useEffect, useState } from "react";
import AuthContext from "../../context/AuthContext";
import GetApiUrlContext from "../../context/GetApiUrlContext";
import useAxios from "../../utils/useAxios";
import Navbar from "../navbar";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import TicketsCompanyReportTable from "./ticketsCompanyReportTable";
// import i18next from "i18next";
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const TicketsCompanyReport = () => {
  const { t } = useTranslation();
  let { company } = useContext(AuthContext);
  const apiUrl = useContext(GetApiUrlContext);
  const labels = [
    t("months.january"),
    t("months.february"),
    t("months.march"),
    t("months.april"),
    t("months.may"),
    t("months.june"),
    t("months.july"),
    t("months.august"),
    t("months.september"),
    t("months.october"),
    t("months.november"),
    t("months.december"),
  ];
  const [ticketsCounts, setticketsCounts] = useState([]);
  const options = {
    legend: {
      display: false,
    },
    maintainAspectRatio: false,
    responsive: true,
  };
  const data = {
    labels: getLastYearMonthes(),

    datasets: [
      {
        label: "Tickets Counts",
        backgroundColor: "rgb(255, 99, 132)",
        borderColor: "rgb(255, 99, 132)",
        data: ticketsCounts,
      },
    ],
  };
  let axiosAuth = useAxios();

  let getData = async (company_id) => {
    if (company_id) {
      let response = await axiosAuth.get(
        `${apiUrl}/reports/tickets_count_of_company/${company_id}`
      );
      setticketsCounts(response.data);
    }
  };

  function getLastYearMonthes() {
    const now = new Date();
    let lastYearMonths = [];
    for (var i = 0; i < 13; i++) {
      var future = new Date(now.getFullYear(), now.getMonth() + i, 1);
      var month = labels[future.getMonth()];
      lastYearMonths.push(month);
    }
    return lastYearMonths;
  }

  useEffect(() => {
    getData(company.id);
  }, []);

  return (
    <React.Fragment>
      <Navbar />
      <div className="container" style={{ width: "1000px" }}>
        <div className="text-center mb-5">
          <h2>{t("last_year_tickets_counts_by_months_per_company")}</h2>
        </div>
        <div className="text-center">
          <Line options={options} data={data} />
        </div>
        <div className="mt-5">
          <TicketsCompanyReportTable data={data} />
        </div>
      </div>
    </React.Fragment>
  );
};

export default TicketsCompanyReport;
