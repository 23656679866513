import React, { useContext } from "react";

import { useHistory } from "react-router-dom";
import AuthContext from "../context/AuthContext";
import ListCompanies from "./listCompanies";
import Navbar from "./navbar";

const ChooseCompany = (props) => {
  // const [ticket, setTicket] = useState();

  let history = useHistory();
  let { companies, setCompany } = useContext(AuthContext);

  // useEffect(() => {
  //   getTicket(params.id, params.company_id);
  // });

  const handleChoose = (company) => {
    setCompany(company);
    localStorage.setItem("currentCompany", JSON.stringify(company));

    history.push("/tickets");
  };

  return (
    <React.Fragment>
      <Navbar />
      <ListCompanies companies={companies} handleclick={handleChoose} />
    </React.Fragment>
  );
};

export default ChooseCompany;
