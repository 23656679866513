import React, { useContext, useState } from "react";
import GetApiUrlContext from "../../context/GetApiUrlContext";
import useAxios from "../../utils/useAxios";
import { useTranslation } from "react-i18next";
import Input from "../common/input";
import Button from "../common/button";
import CustomAlert from "../common/customAlert";
import useRegisterInputs from "../useRegisterInputs";
import useEmployeeInputs from "./useEmployeeInputs";
import chekIfpasswordsConfirmed from "../../utils/useChekIfpasswordsConfirmed";
const CreateEmployee = (props) => {
  const apiUrl = useContext(GetApiUrlContext);
  const { company_id } = props;
  const { t } = useTranslation();
  const [employee, setEmployee] = useState({
    email: "",
    password: "",
    confirm_password: "",
    phone: "",
    address: "",
    city: "",
    country: "",
    postal_code: "",
    role_title: "",
    first_name: "",
    last_name: "",
    role: "support",
    company: company_id,
  });
  const [showAlert, setShowAlert] = useState(false);

  const axiosAuth = useAxios();

  const handleChange = (e) => {
    setEmployee((values) => ({ ...values, [e.target.name]: e.target.value }));
  };

  const createEmployee = async (e) => {
    e.preventDefault();
    let connectionString = `${apiUrl}/employees/${company_id}`;
    let response = await axiosAuth.post(connectionString, employee);
    if (response.status === 201) {
      setShowAlert(true);
      console.log(response.data);
    }
  };

  return (
    <React.Fragment>
      <div>
        <form onSubmit={createEmployee}>
          {useRegisterInputs().map((input) => (
            <Input
              label_classname={input.label_classname}
              clearfocused="false"
              key={input.id}
              {...input}
              value={employee[input.name] || ""}
              onChange={handleChange}
            />
          ))}

          {!chekIfpasswordsConfirmed(
            employee.password,
            employee.confirm_password
          ) && (
            <span style={{ fontSize: "14px", padding: "2px", color: "red" }}>
              {t("alerts.passwords_doesnt_match")}
            </span>
          )}

          {useEmployeeInputs(employee).map((input) => (
            <Input
              label_classname={input.label_classname}
              key={input.id}
              clearfocused="false"
              {...input}
              value={employee[input.name] || ""}
              onChange={handleChange}
            />
          ))}
          <div className="text-center mt-3 d-grid gap-2">
            <Button
              id="createEmployee"
              type="submit"
              className="btn btn-primary"
              label={t("add")}
            />
          </div>
        </form>
        <CustomAlert
          show={showAlert}
          setShow={setShowAlert}
          dialog={t("alerts.employee_created")}
        />
      </div>
    </React.Fragment>
  );
};

export default CreateEmployee;
