export const react_select_custom_style = {
  option: (base, state) => ({
    ...base,
    textAlign: "center",
    "&:hover": {
      background: "#6da8fd",
      color: "white",
    },
    background: state.isFocused ? "#6da8fd" : "white",
    color: state.isFocused ? "white" : "black",
  }),
};
