import React, { useContext, useEffect, useState } from "react";
import AuthContext from "../../context/AuthContext";
import GetApiUrlContext from "../../context/GetApiUrlContext";
import useAxios from "../../utils/useAxios";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import Navbar from "../navbar";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import TicketsCustomerReportTable from "./ticketsCustomerReportTable";
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const TicketsCustomerReport = () => {
  const { t } = useTranslation();
  let { company, user } = useContext(AuthContext);
  console.log("user is: ", user);
  const apiUrl = useContext(GetApiUrlContext);
  const labels = [
    t("months.january"),
    t("months.february"),
    t("months.march"),
    t("months.april"),
    t("months.may"),
    t("months.june"),
    t("months.july"),
    t("months.august"),
    t("months.september"),
    t("months.october"),
    t("months.november"),
    t("months.december"),
  ];
  const [customer, setCustomer] = useState({});
  const [customers, setCustomers] = useState([]);
  const [ticketsCounts, setticketsCounts] = useState([]);
  const options = {
    legend: {
      display: false,
    },
    maintainAspectRatio: false,
    responsive: true,
  };
  const data = {
    labels: getLastYearMonthes(),

    datasets: [
      {
        label: "Tickets Counts",
        backgroundColor: "rgb(255, 99, 132)",
        borderColor: "rgb(255, 99, 132)",
        data: ticketsCounts,
      },
    ],
  };

  const customStyle = {
    option: (base, state) => ({
      ...base,
      textAlign: "center",
    }),
  };
  let axiosAuth = useAxios();

  let getData = async (customer) => {
    setCustomer(customer);
    if (company) {
      let response = await axiosAuth.get(
        `${apiUrl}/reports/tickets_count_of_customer/${company.id}/${customer.id}`
      );
      setticketsCounts(response.data);
    }
  };
  let getDataForCustomer = () => {
    if (user && user.customer_id) {
      let user_customer = {
        id: user.customer_id,
        name: user.name,
      };
      getData(user_customer);
    }
  };

  function getLastYearMonthes() {
    const now = new Date();
    let lastYearMonths = [];
    for (var i = 0; i < 13; i++) {
      var future = new Date(now.getFullYear(), now.getMonth() + i, 1);
      var month = labels[future.getMonth()];
      lastYearMonths.push(month);
    }
    return lastYearMonths;
  }

  let getCustomers = async () => {
    let response;
    if (user.role === "employee") {
      response = await axiosAuth.get(`${apiUrl}/customers/${company.id}`);
      if (response.status === 200) {
        response.data = _.sortBy(response.data, "name");
        setCustomers(response.data);
      }
    }
  };
  useEffect(() => {
    getCustomers();
    getDataForCustomer();
    // getData(company.id);
  }, []);

  return (
    <React.Fragment>
      <Navbar />
      <div className="container" style={{ width: "1000px" }}>
        <div className="text-center mb-3">
          <h2>
            {user.role === "employee"
              ? t("last_year_tickets_counts_by_months_per_customer")
              : t("annual_tickets_report")}
          </h2>
        </div>

        {user.role === "employee" && (
          <div className="col  mb-5 justify-content-center">
            <label htmlFor="select-customers">{t("choose_customer")}</label>
            <Select
              id="select-customers"
              className="text-center"
              styles={customStyle}
              options={customers}
              value={customer}
              getOptionLabel={(options) => options["name"]}
              getOptionValue={(options) => options["id"]}
              onChange={(options) => getData(options)}
            />
          </div>
        )}

        {ticketsCounts.length > 0 && (
          <div>
            <div className="text-center">
              <Line options={options} data={data} />
            </div>
            <div
              className="row"
              style={{ width: "50%", marginLeft: "auto", marginRight: "auto" }}
            ></div>
            <div className="mt-5">
              <TicketsCustomerReportTable data={data} />
            </div>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default TicketsCustomerReport;
