import React from "react";
import { useTranslation } from "react-i18next";
import Table from "../common/table";

const TicketsCompanyReportTable = ({ data }) => {
  const { t } = useTranslation();

  let dataTable = [];
  data.labels.forEach((month, index) => {
    dataTable.unshift({
      month: month,
      count: data.datasets[0].data[index],
    });
  });

  let columns = [
    {
      path: "month",
      label: t("month"),
      key: "month",
    },
    {
      path: "count",
      label: t("tickets_count"),
      key: "count",
    },

    // { path: "count", label: t("count"), key: "count" },
  ];

  return (
    <React.Fragment>
      <Table columns={columns} data={dataTable} />
    </React.Fragment>
  );
};

export default TicketsCompanyReportTable;
