import React, { useContext, useEffect, useState } from "react";
import GetApiUrlContext from "../../context/GetApiUrlContext";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Navbar from "../navbar";
import Select from "react-select";
import useAxios from "../../utils/useAxios";
import { apiUrl } from "../../config/config.json";
import Input from "../common/input";
import TextArea from "../common/textArea";
import Button from "../common/button";
import ChangePassword from "../changePassword";
import Contacts from "../contacts/contacts";
import CustomAlert from "../common/customAlert";
import useCustomerInputes from "./useCustomerInputs";

const EditCustomer = () => {
  const apiUrl = useContext(GetApiUrlContext);
  const params = useParams();
  const { t } = useTranslation();
  let axiosAuth = useAxios();
  const [showLogin, setShowLogin] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [showContacts, setShowContacts] = useState(false);
  const [customer, setCustomer] = useState({
    name: "",
    email: "",
    phone: "",
    category: [],
    contact: [],
    notes: "",
    is_blocked: "",
    web: "",
    address: "",
    city: "",
    country: "",
    postal_code: "",
  });

  const [categories, setCategories] = useState();

  let getCategories = async () => {
    let response = await axiosAuth.get(
      `${apiUrl}/categories/${params.company_id}`
    );
    if (response.status === 200) {
      setCategories(response.data);
    }
  };

  const getCustomer = async () => {
    let response = await axiosAuth.get(
      `${apiUrl}/customers/${params.company_id}/${params.id}`
    );
    setCustomer(response.data);
  };

  const editCustomer = async (e) => {
    e.preventDefault();
    let connectionString = `${apiUrl}/customers/${params.company_id}/${params.id}`;
    let response = await axiosAuth.put(connectionString, customer);
    setShowAlert(true);
    console.log(response.data);
  };

  const handleChange = (e) => {
    setCustomer((values) => ({ ...values, [e.target.name]: e.target.value }));
  };

  const handleShowLogin = () => {
    setShowLogin((showLogin) => !showLogin);
  };

  const handleShowContacts = () => {
    setShowContacts((showContacts) => !showContacts);
  };

  useEffect(() => {
    getCustomer();
    getCategories();
  }, []);

  return (
    <React.Fragment>
      <Navbar />
      <div className="container w-50 mx-auto mt-3">
        <h1 className="text-center text-primary mt-3">
          {t("edit_show_customer")}
        </h1>
        <div className="row">
          <div className="col">
            <Button
              onClick={handleShowLogin}
              id="changeLoginBtn"
              className="btn btn-outline-secondary"
              type="button"
              label={t("change_password")}
            />
          </div>
          <div className="col">
            <Button
              onClick={handleShowContacts}
              id="showContactsBtn"
              className="btn btn-outline-secondary"
              type="button"
              label={t("show_contacts")}
            />
          </div>
        </div>
        {showLogin && (
          <ChangePassword
            company_id={params.company_id}
            email={customer.email}
          />
        )}
        {showContacts && (
          <div className="div-contact">
            <Contacts
              customer_id={customer.id}
              contacts={customer.contact}
              company_id={params.company_id}
            />
          </div>
        )}
        <CustomAlert
          show={showAlert}
          setShow={setShowAlert}
          dialog={t("alerts.customer_updated")}
        />
        <form onSubmit={editCustomer}>
          <Input
            key={customer.email}
            clearfocused="false"
            name="email"
            type="email"
            value={customer.email || ""}
            onChange={handleChange}
          />
          {useCustomerInputes().map((input) => (
            <Input
              clearfocused="false"
              key={input.id}
              {...input}
              value={customer[input.name] || ""}
              onChange={handleChange}
            />
          ))}

          <div className="col">
            <label htmlFor="category">{t("categories")}</label>
            <Select
              inputId="category"
              // placeholder="Category"
              options={categories}
              value={customer.category}
              getOptionLabel={(options) => options["name"]}
              getOptionValue={(options) => options["id"]}
              onChange={(e) => setCustomer({ ...customer, category: e })}
              isMulti={true}
            />
          </div>
          <div className="mt-2">
            <TextArea
              dir="rtl"
              className="textArea"
              onChange={handleChange}
              value={customer.notes}
              label={t("notes")}
              name="notes"
              errorMessage={t("errors.error_notes")}
            />
          </div>
          <div className="text-center mt-3 d-grid gap-2">
            <Button
              id="editCustomer"
              className="btn btn-primary"
              type="submit"
              label={t("update")}
            />
          </div>
        </form>
      </div>
    </React.Fragment>
  );
};

export default EditCustomer;
