import React from "react";
import Tippy from "@tippyjs/react";
import { Link } from "react-router-dom";
const LinkIcon = ({ path, className, tippy_content, ...others }) => {
  return (
    <Tippy
      {...others}
      theme="light"
      maxWidth="100%"
      maxHeight="100%"
      placement="top-start"
      content={tippy_content}
    >
      <Link to={path}>
        <i className={className}></i>
      </Link>
    </Tippy>
  );
};

export default LinkIcon;
