import React from "react";
import { useTranslation } from "react-i18next";
import Table from "../common/table";

const TicketsCustomersReportTable = ({ data }) => {
  const { t } = useTranslation();

  let columns = [
    {
      path: "customer_name",
      label: t("customer_name"),
      key: "customer_name",
    },
    {
      path: "count",
      label: t("tickets_count"),
      key: "count",
    },
  ];

  return (
    <React.Fragment>
      <Table columns={columns} data={data} />
    </React.Fragment>
  );
};

export default TicketsCustomersReportTable;
