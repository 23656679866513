import { useTranslation } from "react-i18next";

export default function useContactInputs() {
  const { t } = useTranslation();
  const employee_inputs = [
    {
      id: "first_name",
      name: "first_name",
      type: "text",
      placeholder: t("first_name"),
      label: t("first_name"),
      errorMessage: t("errors.error_first_name"),
      required: true,
      pattern: "^[א-תA-Za-z_ ]{3,16}$",
    },
    {
      id: "last_name",
      name: "last_name",
      type: "text",
      placeholder: t("last_name"),
      label: t("last_name"),
      errorMessage: t("errors.error_last_name"),
      required: true,
      pattern: "^[א-תA-Za-z_ ]{3,16}$",
    },

    // {
    //   id: "password",
    //   name: "password",
    //   type: "password",
    //   placeholder: t("password"),
    //   label: t("password"),
    //   errorMessage: t("errors.error_password"),
    // },
    // {
    //   id: "confirm_password",
    //   name: "confirm_password",
    //   type: "password",
    //   placeholder: t("confirm_password"),
    //   label: t("confirm_password"),
    //   errorMessage: t("errors.error_confirm_password"),
    // },
    {
      id: "phone",
      name: "phone",
      type: "text",
      placeholder: t("phone"),
      label: t("phone"),
      errorMessage: t("errors.error_phone"),
      required: true,
      pattern: "^[0-9-]{7,11}$",
    },

    {
      id: "address",
      name: "address",
      type: "text",
      placeholder: t("address"),
      label: t("address"),
      errorMessage: t("errors.error_address"),
      required: true,
    },
    {
      id: "city",
      name: "city",
      type: "text",
      placeholder: t("city"),
      label: t("city"),
      errorMessage: t("errors.error_city"),
      required: true,
      pattern: "^[א-תA-Za-z_ ]{3,16}$",
    },
    {
      id: "country",
      name: "country",
      type: "text",
      placeholder: t("country"),
      label: t("country"),
      errorMessage: t("errors.error_country"),
      required: true,
      pattern: "^[א-תA-Za-z_ ]{3,16}$",
    },
    {
      id: "postal_code",
      name: "postal_code",
      type: "text",
      placeholder: t("postal_code"),
      label: t("postal_code"),
      errorMessage: t("errors.error_postal_code"),
      required: true,
    },
    {
      id: "role_title",
      name: "role_title",
      type: "text",
      placeholder: t("role_title"),
      label: t("role_title"),
      errorMessage: t("errors.error_role_title"),
      required: true,
    },
  ];

  return employee_inputs;
}
