import React from "react";
import ReCAPTCHA from "react-google-recaptcha";

const GlobalRecaptcha = (props) => {
  const { innerref } = props;
  return (
    <div className="d-flex justify-content-center mt-2">
      <ReCAPTCHA ref={innerref} {...props} />
    </div>
  );
};

export default GlobalRecaptcha;
