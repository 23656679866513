import React from "react";
import Tippy from "@tippyjs/react";
const TooltipIcon = ({ className, tippy_content, ...others }) => {
  return (
    <Tippy
      {...others}
      theme="light-border"
      maxWidth="100%"
      maxHeight="100%"
      placement="top-start"
      content={tippy_content}
    >
      <i className={className}></i>
    </Tippy>
  );
};

export default TooltipIcon;
