import React, { useState } from "react";
import EditContact from "./editContact";
import Button from "../common/button";
import { useTranslation } from "react-i18next";
import CreateContact from "./createContact";

const Contacts = (props) => {
  const { contacts, customer_id, company_id } = props;
  const [showNewContact, setShowNewContact] = useState(false);
  const { t } = useTranslation();

  const handleShowNewContact = () => {
    setShowNewContact((showNewContact) => !showNewContact);
  };

  return (
    <React.Fragment>
      <Button label={t("add_new_contact")} onClick={handleShowNewContact} />
      {showNewContact && <CreateContact customer_id={customer_id} />}
      {contacts.map((contact, index) => (
        <div>
          <EditContact
            key={contact.contact_id}
            contactObj={contact}
            company_id={company_id}
          />
        </div>
      ))}
    </React.Fragment>
  );
};

export default Contacts;
