import React, { useContext, useEffect, useState } from "react";
import GetApiUrlContext from "../context/GetApiUrlContext";
import Table from "./common/table";
import { useTranslation } from "react-i18next";
import Pagination from "./common/pagination";
import { paginate } from "../utils/paginate";

const ListCompanies = (props) => {
  const apiUrl = useContext(GetApiUrlContext);
  const { handleclick } = props;
  const { t } = useTranslation();
  let [pageProps, setPageProps] = useState({ currentPage: 1, pageSize: 5 });
  let [sortColumn, setSortColumn] = useState({
    path: "company_name",
    order: "asc",
  });

  // useEffect(() => {
  //   setCompanies(JSON.parse(localStorage.getItem("companies")));
  //   console.log("comapnies are: ", companies);
  // }, []);
  let getComapnyRow = (company) => {
    return (
      <div
        onClick={() => {
          handleclick(company);
        }}
      >
        <img className="logo" src={apiUrl + "/" + company.comapny_logo}></img>
        <span style={{ marginLeft: "20px", fontSize: "20px" }}>
          {company.company_name}
        </span>
      </div>
    );
  };

  let columns = [
    {
      path: "company_name",
      label: "",
      key: "company_name",
      content: getComapnyRow,
    },
  ];

  // useEffect(() => {
  //   let comapniesObj = JSON.parse(localStorage.getItem("companies"));
  //   setCompanies(comapniesObj);
  // }, []);

  return (
    <div>
      <div className="text-center">
        <h2>{t("choose_company")}</h2>
      </div>
      <div
        style={{ textAlign: "center" }}
        className="container select-company-container mt-3  rounded-3"
      >
        <Table
          data={JSON.parse(localStorage.getItem("companies"))}
          columns={columns}
        />
      </div>
    </div>
  );
};

export default ListCompanies;
