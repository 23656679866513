import React, { useState } from "react";
import "./input.css";

const TextArea = ({
  onChange,
  label,
  name,
  value,
  width,
  rows,
  border,
  errorMessage,
  ...rest
}) => {
  const [focused, setFocused] = useState(false);
  // const [val, setVal] = useState("");

  // const setUndefinedValue = () => {
  //   if (value === null) value = "";
  // };
  // useEffect(() => {
  //   setUndefinedValue();
  // });
  let customDivClass = " form-group ";
  if (width) customDivClass += ` ${width}`;
  let customTextAreaClass = "form-control formInput";
  if (!border) customTextAreaClass += " textAreaWithoutBorderClass";
  const handleFocused = (e) => {
    setFocused(true);
  };
  return (
    <div className={customDivClass}>
      {label && (
        <label htmlFor="textArea" className="form-label">
          {label}
        </label>
      )}
      <textarea
        {...rest}
        onFocus={handleFocused}
        focused={focused.toString()}
        onChange={onChange}
        name={name}
        value={value === null ? "" : value}
        className={customTextAreaClass}
        id="textarea"
        rows={rows ? rows : 5}
        // border="none"
      ></textarea>
      <span className="alertMessage">{errorMessage}</span>
    </div>
  );
};

export default TextArea;
