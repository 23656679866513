import React, { useContext, useState } from "react";
import GetApiUrlContext from "../../context/GetApiUrlContext";
import useContactInputs from "./useContactInputs";
import { useTranslation } from "react-i18next";
import Input from ".././common/input";
import Button from "../common/button";
import useAxios from "../../utils/useAxios";
import DeleteContact from "./deleteContact";
import CustomAlert from "../common/customAlert";

const EditContact = (props) => {
  const apiUrl = useContext(GetApiUrlContext);
  const { contactObj } = props;

  const [contact, setContact] = useState(contactObj);
  const [showAlert, setShowAlert] = useState(false);

  const axiosAuth = useAxios();

  const handleChange = (e) => {
    setContact((values) => ({ ...values, [e.target.name]: e.target.value }));
  };

  const editContact = async (e) => {
    e.preventDefault();
    let connectionString = `${apiUrl}/contacts/${contact.contact_id}`;
    let response = await axiosAuth.put(connectionString, contact);
    setShowAlert(true);
    console.log(response.data);
  };

  const { t } = useTranslation();

  return (
    <React.Fragment>
      <div className="div-contact mb-5 ">
        <DeleteContact contact_id={contact.contact_id} />
        <CustomAlert
          show={showAlert}
          setShow={setShowAlert}
          dialog={t("alerts.contact_updated")}
        />
        <form onSubmit={editContact}>
          {useContactInputs().map((input) => (
            <Input
              label_classname={input.label_classname}
              key={input.id}
              {...input}
              value={contact[input.name] || ""}
              onChange={handleChange}
            />
          ))}
          <div className="text-center mt-3 d-grid gap-2">
            <Button
              id="editContact"
              type="submit"
              className="btn btn-primary"
              label={t("update")}
            />
          </div>
        </form>
      </div>
    </React.Fragment>
  );
};
export default EditContact;
