import React, { useContext, useState } from "react";
import GetApiUrlContext from "../../context/GetApiUrlContext";
import ButtonIcon from "../common/buttonIcon";
import CustomAlert from "../common/customAlert";
import useAxios from "../../utils/useAxios";
import { useTranslation } from "react-i18next";

const DeleteContact = (props) => {
  const apiUrl = useContext(GetApiUrlContext);
  const { contact_id } = props;
  const [showAlert, setShowAlert] = useState(false);
  const { t } = useTranslation();
  const axiosAuth = useAxios();
  const deleteContact = async () => {
    let connectionString = `${apiUrl}/contacts/${contact_id}`;
    await axiosAuth.delete(connectionString);
    setShowAlert(true);

    // window.location.reload(false);
  };

  return (
    <React.Fragment>
      <CustomAlert
        dialog={t("alerts.conatct_deleted")}
        show={showAlert}
        setShow={setShowAlert}
      />

      <div dir="auto">
        <ButtonIcon
          classes="fa fa-trash fa-3x btn btn-lg btn-outline-secondary"
          aria-hidden="true"
          onclick={deleteContact}
        />
      </div>
    </React.Fragment>
  );
};

export default DeleteContact;
