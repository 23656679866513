import React, { useContext, useState } from "react";
import GetApiUrlContext from "../context/GetApiUrlContext";
import { useTranslation } from "react-i18next";
import useAxios from "../utils/useAxios";
import Input from "./common/input";
import Button from "./common/button";
import CustomAlert from "./common/customAlert";
import chekIfpasswordsConfirmed from "../utils/useChekIfpasswordsConfirmed";
const ChangePassword = (props) => {
  const apiUrl = useContext(GetApiUrlContext);
  const { email, company_id } = props;
  const [loginInputes, setLoginInputs] = useState({
    company_id: company_id,
    username: email,
    new_password: "",
    confirm_password: "",
  });
  const [showAlertPasswordChanged, setshowAlertPasswordChanged] =
    useState(false);
  const { t } = useTranslation();
  const axiosAuth = useAxios();

  const handleChange = (e) => {
    setLoginInputs((values) => ({
      ...values,
      [e.target.name]: e.target.value,
    }));
  };

  const changePassword = async (e) => {
    e.preventDefault();
    if (loginInputes.new_password === loginInputes.confirm_password) {
      if (loginInputes.new_password === "") {
        loginInputes.new_password = undefined;
        loginInputes.confirm_password = undefined;
      }
      let connectionString = `${apiUrl}/account/change_password`;
      let response = await axiosAuth.put(connectionString, loginInputes);
      setshowAlertPasswordChanged(true);
    }
  };

  const login_inputs = [
    {
      id: "new_password",
      name: "new_password",
      type: "password",
      placeholder: t("new_password"),
      label: t("new_password"),
      pattern: "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$",
      errorMessage: t("errors.error_password"),
    },
    {
      id: "confirm_password",
      name: "confirm_password",
      type: "password",
      placeholder: t("confirm_password"),
      label: t("confirm_password"),
      errorMessage: t("errors.error_confirm_password"),
    },
  ];

  return (
    <React.Fragment>
      <div className="container w-50 mx-auto mt-3 div-contact">
        {/* <h1 className="text-center text-primary mt-3">Change Login</h1> */}
        {showAlertPasswordChanged && (
          <CustomAlert
            dialog={t("alerts.password_changed")}
            setShow={setshowAlertPasswordChanged}
            show={showAlertPasswordChanged}
          />
        )}
        <form onSubmit={changePassword}>
          {login_inputs.map((input) => (
            <Input
              key={input.id}
              clearfocused="false"
              {...input}
              value={loginInputes[input.name]}
              onChange={handleChange}
            />
          ))}
          {!chekIfpasswordsConfirmed(
            loginInputes.new_password,
            loginInputes.confirm_password
          ) && (
            <span style={{ fontSize: "14px", padding: "2px", color: "red" }}>
              {t("alerts.passwords_doesnt_match")}
            </span>
          )}
          <div className="text-center mt-3 d-grid gap-2">
            <Button type="submit" className="btn btn-danger" label="Update" />
          </div>
        </form>
      </div>
    </React.Fragment>
  );
};

export default ChangePassword;
