import { useContext } from "react";
import { useParams, useHistory } from "react-router-dom";
import useAxios from "../../utils/useAxios";
import GetApiUrlContext from "../../context/GetApiUrlContext";
import ListCompanies from "../listCompanies";

const ForwoardTicket = () => {
  const apiUrl = useContext(GetApiUrlContext);
  const params = useParams();
  const axiosAuth = useAxios();
  const history = useHistory();
  // const { companies } = useContext(AuthContext);

  let connectionString = `${apiUrl}/tickets/${params.company_id}/${params.id}/`;

  let changeTicketComapny = async (company) => {
    await axiosAuth.patch(connectionString, { company: company.id });
    history.push("/tickets");
  };

  return <ListCompanies handleclick={changeTicketComapny} />;
};

export default ForwoardTicket;
