import axios from "axios";
import { useContext } from "react";
import GetApiUrlContext from "../context/GetApiUrlContext";
import jwt_decode from "jwt-decode";
import dayjs from "dayjs";
import AuthContext from "../context/AuthContext";

const useAxios = () => {
  const apiUrl = useContext(GetApiUrlContext);
  const { authTokens, setUser, setAuthTokens } = useContext(AuthContext);
  const authAxios = axios.create({
    baseURL: apiUrl,
    timeout: 4000,
    headers: {
      Authorization: `Bearer ${authTokens?.access}`,
    },
  });

  authAxios.interceptors.request.use(async (req) => {
    const user = jwt_decode(authTokens.access);
    const isExpired = dayjs.unix(user.exp).diff(dayjs()) < 1;
    if (!isExpired) return req;
    const response = await axios.post(`${apiUrl}/auth/token/refresh/`, {
      refresh: authTokens.refresh,
    });

    localStorage.setItem("authTokens", JSON.stringify(response.data));
    setAuthTokens(response.data);
    setUser(jwt_decode(response.data.access));
    req.headers.Authorization = `Bearer ${response.data.access}`;
    return req;
  });

  return authAxios;
};

export default useAxios;
