import React, { useContext } from "react";
import GetApiUrlContext from "../context/GetApiUrlContext";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { Link } from "react-router-dom";
import AuthContext from "../context/AuthContext";
import GlobeIconMenu from "./common/globeIconMenu";
import "./navbar.css";

const Navbar = () => {
  const apiUrl = useContext(GetApiUrlContext);
  const { t } = useTranslation();
  let { user, logoutUser, company, companies } = useContext(AuthContext);
  if (localStorage.getItem("currentCompany")) {
    company = JSON.parse(localStorage.getItem("currentCompany"));
  }

  return (
    <nav
      // dir="ltr"
      className="navbar navbar-expand-lg navbar-light"
      // style={{ backgroundColor: "SeaShell" }}
    >
      <div className="container-fluid">
        <Link className="navbar-brand" to="#">
          <img
            src={`${apiUrl}/${
              company
                ? company.comapny_logo
                : localStorage.getItem("currentCompany")
            }`}
            alt=""
            // width="30"

            className="d-inline-block align-text-top logo"
          ></img>
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContentRight"
          aria-controls="navbarSupportedContentRight"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          {user && user.role === "employee" && (
            <li className="nav-item dropdown">
              <span
                style={{ color: "grey" }}
                className="nav-link dropdown-toggle"
                // href="#"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                {t("menu")}
              </span>
              <ul
                className={`dropdown-menu ${
                  i18next.language === "he" ? " dropdown-rtl" : " dropdown-ltr"
                }`}
              >
                <li>
                  <Link to="/tickets" className="dropdown-item">
                    {t("tickets")}
                  </Link>
                </li>
                <li>
                  <Link to="/customers" className="dropdown-item">
                    {t("customers")}
                  </Link>
                </li>
                <li>
                  <Link to="/employees" className="dropdown-item">
                    {t("employees")}
                  </Link>
                </li>
                <li>
                  <a className="dropdown-item" href="#">
                    {t("reports")} &raquo;
                  </a>
                  <ul
                    dir="auto"
                    className={`dropdown-menu dropdown-submenu ${
                      i18next.language === "he"
                        ? " dropdown-rtl"
                        : " dropdown-ltr"
                    }`}
                  >
                    <li>
                      <Link
                        className="dropdown-item "
                        to="/tickets_cutomers_report"
                      >
                        {t("count_tickets_per_each_customer_by_time")}
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="dropdown-item "
                        to="/tickets_customer_report"
                      >
                        {t("last_year_tickets_counts_by_months_per_customer")}
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="dropdown-item "
                        to="/tickets_company_report"
                      >
                        {t("last_year_tickets_counts_by_months_per_company")}
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="dropdown-item "
                        to="/tickets_employees_report"
                      >
                        {t("count_tickets_per_each_employee_by_time")}
                      </Link>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
          )}

          <ul className="navbar-nav  mb-2 mb-lg-0">
            {user && user.role === "customer" && (
              <li className="nav-item">
                <Link to="/tickets" className="nav-link">
                  {t("tickets")}
                </Link>
              </li>
            )}

            <li className="nav-item">
              <Link
                to={`/tickets/createTicket/${company.id}`}
                className="nav-link"
              >
                {t("create-ticket")}
              </Link>
            </li>
            {user && user.role === "customer" && (
              <li className="nav-item">
                <Link className="nav-link" to="/tickets_customer_report">
                  {t("annual_tickets_report")}
                </Link>
              </li>
            )}

            {companies && companies.length > 1 && (
              <li className="nav-item">
                <Link to="choose_company" className="nav-link">
                  {t("switch_company")}
                </Link>
              </li>
            )}
          </ul>
        </div>
      </div>

      <div
        className="collapse navbar-collapse"
        id="navbarSupportedContentRight"
      >
        <ul className="navbar-nav me-auto mb-2 mb-lg-0">
          <li className="nav-item">
            <GlobeIconMenu />
          </li>
          {user && user.role === "employee" && (
            <li className="nav-item">
              <Link
                to={`/employees/editEmployee/${company.id}/${user.employee_id}`}
                className="nav-link"
              >
                {t("hi")} {user.username}
              </Link>
            </li>
          )}
          {user && user.role === "customer" && (
            <li className="nav-item">
              <Link
                to={`/customers/editCustomer/${company.id}/${user.customer_id}`}
                className="nav-link"
              >
                {t("hi")} {user.username}
              </Link>
            </li>
          )}
          {user ? (
            <li className="nav-item">
              <button className="btn btn-lg btn-link" onClick={logoutUser}>
                {t("log_out")}
              </button>
            </li>
          ) : (
            <li className="nav-item">
              <Link to="/login" className="nav-link">
                {t("login")}
              </Link>
            </li>
          )}
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
