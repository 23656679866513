import React, { useRef, useContext, useEffect, useState } from "react";
import GetApiUrlContext from "../../context/GetApiUrlContext";

import i18n from "i18next";
// import ReCAPTCHA from "react-google-recaptcha";
import GlobalRecaptcha from "../common/globalRecaptcha";
import AuthContext from "../../context/AuthContext";
import axios from "axios";
import { useTranslation } from "react-i18next";
import Input from "../common/input";
import Button from "../common/button";
import TextArea from "../common/textArea";
import { react_select_custom_style } from "../../config/customStyles";

import Select from "react-select";
import Navbar from "../navbar";
import GlobeIconMenu from "../common/globeIconMenu";
import CustomAlert from "../common/customAlert";
import FileInput from "../common/fileInput";

function CreateTicket(props) {
  const apiUrl = useContext(GetApiUrlContext);
  let ticketObj = {
    full_name: "",
    customer_name: "",
    email: "",
    phone: "",
    category: "",
    description: "",
    company: props.match.params.company_id,
  };
  const { t } = useTranslation();
  const [alertCreated, setAlertCreated] = useState({
    id: "",
    ticket_time: "",
    clearfocused: false,
  });
  const [ticket, setTicket] = useState(ticketObj);
  const [selectedFile, setSelectedFile] = useState(null);
  const [categories, setCategories] = useState([]);
  const { company, user } = useContext(AuthContext);
  const [buttonStateIsDisabled, setButtonState] = useState(true);
  // const createticketref = useRef(null);
  // const fileref = useRef();
  const captchaRef = useRef(null);
  // console.log("re-rendered");

  const inputs = [
    {
      id: "full_name",
      name: "full_name",
      type: "text",
      placeholder: t("full_name"),
      label: t("full_name"),
      errorMessage: t("errors.error_full_name"),
      required: true,
      pattern: "^.{3,16}$",
    },
    {
      id: "customer_name",
      name: "customer_name",
      type: "text",
      placeholder: t("company"),
      label: t("company"),
      errorMessage: t("errors.error_company_name"),
      required: true,
      pattern: "^.{3,16}$",
    },
    {
      id: "email",
      name: "email",
      type: "email",
      placeholder: t("email"),
      label: t("email"),
      errorMessage: t("errors.error_email"),
      required: true,
    },
    {
      id: "phone",
      name: "phone",
      type: "text",
      placeholder: t("phone"),
      label: t("phone"),
      required: true,
      errorMessage: t("errors.error_phone"),
      pattern: "^[0-9-]{7,11}$",
    },
  ];

  useEffect(() => {
    async function getCategoriesAsync() {
      const { data: categories } = await axios.get(
        apiUrl + `/categories/${ticketObj.company}`
      );
      setCategories(categories);
    }
    getCategoriesAsync();
  }, []);

  const handleChange = (e) => {
    // console.log("evet is: ", e);
    setTicket((values) => ({ ...values, [e.target.name]: e.target.value }));
  };
  const handleFileInput = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const handleCaptchaCheck = () => {
    setButtonState(false);
  };

  let sendTicket = async (e) => {
    e.preventDefault();
    let connectionString = `${apiUrl}/tickets/${ticketObj.company}/`;

    let ticketWithFile = new FormData();
    ticketWithFile.append("full_name", ticket.full_name);
    ticketWithFile.append("customer_name", ticket.customer_name);
    ticketWithFile.append("email", ticket.email);
    ticketWithFile.append("phone", ticket.phone);
    ticketWithFile.append("category", ticket.category.id);
    ticketWithFile.append("description", ticket.description);
    ticketWithFile.append("company", ticket.company);
    const recaptchaToken = captchaRef.current.getValue();
    ticketWithFile.append("recaptcha", recaptchaToken);
    captchaRef.current.reset();
    ticketWithFile.append("file", selectedFile);
    let response = await axios.post(connectionString, ticketWithFile);
    // setTicket({ id: response.data.id, ticket_time: response.data.ticket_time });
    setAlertCreated({
      id: response.data.id,
      ticket_time: response.data.ticket_time,
      clearfocused: true,
    });
    setTicket(ticketObj);
    setSelectedFile(null);
  };

  return (
    <div>
      {company.id && user && <Navbar />}

      <div>
        <div className="container mt-3">
          {!user && (
            <React.Fragment>
              <GlobeIconMenu />
              <span className="d-flex justify-content-center">
                <img
                  className="logo"
                  src={`${apiUrl}/media/${ticketObj.company}.png`}
                  alt="logo"
                />
              </span>
            </React.Fragment>
          )}

          <h1 className="text-center mt-1">{t("open_ticket")}</h1>

          {alertCreated && alertCreated.id && (
            <CustomAlert
              setShow={setAlertCreated}
              show={alertCreated}
              title={`${t("alerts.ticket_created")} ${t(
                "alerts.ticket_number"
              )}: ${alertCreated.id}`}
              dialog={`${t("alerts.ticket_time")}: ${alertCreated.ticket_time}`}
            />
          )}
          <div>
            <form onSubmit={sendTicket}>
              <div className="w-50  mx-auto">
                {inputs.map((input) => (
                  <Input
                    key={input.id}
                    {...input}
                    value={ticket[input.name]}
                    clearfocused={alertCreated.clearfocused.toString()}
                    onChange={handleChange}
                  />
                ))}
                <div className="mt-3">
                  <Select
                    required
                    // className="text-center"
                    styles={react_select_custom_style}
                    inputId="category"
                    placeholder={t("select_subject")}
                    options={categories}
                    value={ticket.category}
                    getOptionLabel={(options) => options[t("name")]}
                    getOptionValue={(options) => options["id"]}
                    onChange={(e) => setTicket({ ...ticket, category: e })}
                  />
                </div>
              </div>
              <div className="w-50  mx-auto">
                <TextArea
                  border={true}
                  className="textArea"
                  onChange={handleChange}
                  value={ticket.description}
                  label={t("description")}
                  name="description"
                  errorMessage={t("errors.error_description")}
                  required={true}
                />
                <div className="d-flex justify-content-center">
                  <FileInput
                    label={t("add_files")}
                    file_label={t("choose_files")}
                    onChange={handleFileInput}
                  />
                </div>

                <GlobalRecaptcha
                  src="https://www.google.com/recaptcha/api.js?hl=es"
                  innerref={captchaRef}
                  sitekey="6Lc7btAhAAAAADGmywDZrsDGBKNzFLKQ-JNI0GAy"
                  onChange={handleCaptchaCheck}
                  hl={i18n.language}
                />

                <div className="text-center mt-2 d-grid gap-2">
                  <Button
                    type="submit"
                    label={t("send_ticket")}
                    disabled={buttonStateIsDisabled}
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreateTicket;
