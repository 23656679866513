import { useContext, createContext, useState, useEffect } from "react";
import axios from "axios";
import GetApiUrlContext from "./GetApiUrlContext";
import jwt_decode from "jwt-decode";
import { useHistory } from "react-router-dom";

const AuthContext = createContext();

export default AuthContext;

export const AuthProvider = ({ children }) => {
  const apiUrl = useContext(GetApiUrlContext);
  let history = useHistory();

  let [authTokens, setAuthTokens] = useState(() =>
    localStorage.getItem("authTokens")
      ? JSON.parse(localStorage.getItem("authTokens"))
      : null
  );

  let [loginResponse, setLoginResponse] = useState({});

  let [user, setUser] = useState(() =>
    localStorage.getItem("authTokens")
      ? jwt_decode(localStorage.getItem("authTokens"))
      : null
  );

  let [loading, setLoading] = useState(true);

  let default_company = {
    comapny_logo: "media/default.png",
    company_name: "Default",
    id: null,
  };

  let [company, setCompany] = useState(() =>
    localStorage.getItem("currentCompany")
      ? JSON.parse(localStorage.getItem("currentCompany"))
      : default_company
  );

  let [companies, setCompanies] = useState(() => {
    if (localStorage.getItem("companies"))
      JSON.parse(localStorage.getItem("companies"));
  });

  let loginUser = async (e) => {
    e.preventDefault();
    let credentials = {
      username: e.target.username.value,
      password: e.target.password.value,
    };

    let response = await axios.post(apiUrl + "/auth/token/", credentials);
    setLoginResponse(response);
    if (response && response.status === 200) {
      let { data } = response;
      setAuthTokens(data);
      localStorage.setItem(
        "companies",
        JSON.stringify(jwt_decode(data.access).companies)
      );
      localStorage.setItem("authTokens", JSON.stringify(data));
      if (localStorage.getItem("currentCompany")) {
        history.push("/tickets");
      } else if (jwt_decode(data.access).companies.length === 1) {
        localStorage.setItem(
          "currentCompany",
          JSON.stringify(jwt_decode(data.access).companies[0])
        );
        history.push("/tickets");
      } else {
        // localStorage.setItem(
        //   "companies",
        //   JSON.stringify(jwt_decode(data.access).companies)
        // );
        history.push("/choose_company");
      }
    }
    return response;
  };

  let logoutUser = () => {
    // console.log("blabla...");
    setAuthTokens(null);
    setUser(null);
    // setCompany(null);
    // setCompanies(null);
    localStorage.removeItem("authTokens");
    localStorage.removeItem("companies");
    localStorage.removeItem("currentCompany");
    // localStorage.clear();
    history.push("/login");
  };

  let contextData = {
    authTokens,
    user,
    company,
    companies,
    loginResponse,
    loginUser,
    logoutUser,
    setAuthTokens,
    setUser,
    setCompany,
    setCompanies,
  };

  useEffect(() => {
    if (authTokens) {
      setUser(jwt_decode(authTokens.access));
    }
    let localStorageComapnies = localStorage.getItem("companies");
    let localStorageCurrentCompany = localStorage.getItem("currentCompany");
    if (localStorageComapnies) setCompanies(JSON.parse(localStorageComapnies));
    if (
      localStorageCurrentCompany &&
      localStorageComapnies &&
      localStorageComapnies.includes(localStorageCurrentCompany) === false
    ) {
      if (JSON.parse(localStorageComapnies).length > 1) {
        history.push("/choose_company");
      } else {
        let currentComapnyFromArray = JSON.parse(localStorageComapnies)[0];
        setCompany(currentComapnyFromArray);
        localStorage.setItem(
          "currentCompany",
          JSON.stringify(currentComapnyFromArray)
        );
      }
    } else {
      // setCompany(default_company);
      // localStorage.setItem("currentCompany", JSON.stringify(default_company));
    }
    setLoading(false);
  }, [authTokens, loading]);

  return (
    <AuthContext.Provider value={contextData}>
      {loading ? null : children}
    </AuthContext.Provider>
  );
};
