import GlobeIcon from "./globeIcon";
import languages from "../../config/languages";
import cookie from "js-cookie";
import i18n from "i18next";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

function GlobeIconMenu() {
  const currentLanguageCode = cookie.get("i18next") || "en";
  const currentLanguage = languages.find((l) => l.code === currentLanguageCode);
  const { t } = useTranslation();

  useEffect(() => {
    document.body.dir = currentLanguage.dir || "ltr";
    document.title = t("app_title");
  }, [currentLanguage, t]);

  return (
    // <div className="container">
    //   <div className="d-flex justify-content-end">
    <div
      className="dropdown"
      // style={{ marginLeft: "80px", marginRight: "80px" }}
    >
      <button
        style={{ color: "gray" }}
        className="btn  dropdown-toggle"
        type="button"
        id="dropdownMenuButton1"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        <GlobeIcon />
      </button>
      <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
        <li>
          <span className="dropdown-item-text">{t("language")}</span>
        </li>
        {languages.map(({ code, name, country_code }) => {
          return (
            <li key={country_code}>
              <button
                className="dropdown-item"
                onClick={() =>
                  i18n.changeLanguage(code) && window.location.reload(false)
                }
                disabled={code === currentLanguageCode}
              >
                <span
                  className={`flag-icon flag-icon-${country_code} mx-2`}
                  style={{
                    opacity: code === currentLanguageCode ? 0.5 : 1,
                  }}
                ></span>
                {name}
              </button>
            </li>
          );
        })}
      </ul>
    </div>
    //   </div>
    // </div>
  );
}

export default GlobeIconMenu;
