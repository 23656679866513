import React, { useContext, useState } from "react";
import GetApiUrlContext from "../../context/GetApiUrlContext";
import ButtonIcon from "../common/buttonIcon";
import { useTranslation } from "react-i18next";
import useAxios from "../../utils/useAxios";
import ConfirmAlert from "../common/confirmAlert";

const TicketFiles = ({ files, company_id }) => {
  const apiUrl = useContext(GetApiUrlContext);
  const [showConfirm, setShowConfirm] = useState(false);
  const { t } = useTranslation();
  const axiosAuth = useAxios();
  const imageExtensions = ["jpeg", "jpg", "png", "gif", "tiff"];

  const handleDeleteFile = async (file_id) => {
    let connectionString = `${apiUrl}/tickets/delete_file/${company_id}/${file_id}`;
    await axiosAuth.delete(connectionString);
    window.location.reload(false);

    // window.location.reload(false);
  };

  const downloadFile = (url, fileName) => {
    fetch(url).then(async (response) => {
      const b = await response.blob();
      let a = document.createElement("a");
      let url_object = URL.createObjectURL(b);
      a.href = url_object;
      a.download = fileName;
      a.click();
    });
  };

  return (
    <div>
      {files.map((item) => (
        <div key={item.file} className="shadow-box">
          <ConfirmAlert
            show={showConfirm}
            setShow={setShowConfirm}
            object_id={item.id}
            handleClick={handleDeleteFile}
          />
          {imageExtensions.includes(item.type) && (
            <a target="_blank" rel="noreferrer" href={`${apiUrl}${item.file}`}>
              <img
                key={item.file}
                src={`${apiUrl}${item.file}`}
                alt=""
                style={{ width: "100%" }}
                className="d-inline-block align-text-top"
                role="button"
              ></img>
            </a>
          )}
          {item.type === "pdf" && (
            <div className="row justify-content-center">
              <a
                href={`${apiUrl}${item.file}`}
                target="_blank"
                rel="noreferrer"
              >
                <div style={{ textAlign: "center" }}>
                  <img
                    src={`${apiUrl}/media/pdf.png`}
                    role="button"
                    style={{ width: "30%" }}
                    alt="pdf"
                  ></img>
                  <br />
                  <label className="mt-3">{item.name}</label>
                </div>
              </a>
            </div>
          )}

          {imageExtensions.includes(item.type) === false &&
            item.type !== "pdf" && (
              <div className="row justify-content-center">
                <a
                  href={`${apiUrl}${item.file}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <div style={{ textAlign: "center" }}>
                    <img
                      src={`${apiUrl}/media/document.png`}
                      role="button"
                      style={{ width: "30%" }}
                      alt="document"
                    ></img>
                    <br />
                    <label>{item.name}</label>
                  </div>
                </a>
              </div>
            )}

          <div key={item.file} className="row justify-content-center mt-3">
            <div className="col-sm-2 col-4 ">
              {/* <a href={`${apiUrl}${item.file}/`} download>
                        <ButtonIcon classes="text-success fa-3x fa fa-arrow-circle-o-down" />
                      </a> */}
              <ButtonIcon
                classes="text-success fa-3x fa fa-arrow-circle-o-down"
                onclick={() => {
                  downloadFile(`${apiUrl}${item.file}`, item.name);
                }}
              />
            </div>
            <div className="col-sm-2 col-4 ">
              <ButtonIcon
                classes="fa fa-minus-circle fa-3x text-danger center-block"
                tippy_content={t("tooltips.delete_file")}
                onclick={() => {
                  setShowConfirm(true);
                }}
              />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default TicketFiles;
