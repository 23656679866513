import { BrowserRouter as Router } from "react-router-dom";
import { Suspense } from "react";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import translation from "./config/translation.json";
import "./index.css";

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .init({
    supportedLngs: ["he", "en"],
    detection: {
      order: ["path", "cookie", "localStorage", "subdomain"],
      caches: ["cookie"],
    },
    resources: {
      en: {
        translation: translation.en,
      },
      he: {
        translation: translation.he,
      },
    },
    fallbackLng: "en",
    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
  });

const loadingMarkup = (
  <div className="py-4 text-center">
    <h2>Loading...</h2>
  </div>
);

ReactDOM.render(
  <Router>
    <Suspense fallback={loadingMarkup}>
      <App />
    </Suspense>
    ,
  </Router>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
