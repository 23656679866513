import React from "react";
import Modal from "react-bootstrap/Modal";
import "./customAlerts.css";
import Button from "./button";
import { useTranslation } from "react-i18next";

const ConfirmAlert = ({
  dialog,
  setShow,
  handleClick,
  object_id,
  show,
  ...other
}) => {
  const { t } = useTranslation();

  return (
    <Modal
      show={show}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      {...other}
    >
      <Modal.Body>{dialog ? dialog : t("are_you_shure")}</Modal.Body>
      <Modal.Footer>
        <Button
          type="button"
          data-bs-dismiss="modal"
          className="btn btn-primary"
          label={t("yes")}
          onClick={() => {
            handleClick(object_id) && setShow(false);
          }}
        />
        <Button
          type="button"
          data-bs-dismiss="modal"
          className="btn btn-secondary"
          label={t("no")}
          onClick={() => {
            setShow(false);
          }}
        />
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmAlert;
