import { createContext, useState, useEffect } from "react";
import config from "../config/config.json";
const GetApiUrlContext = createContext();

export default GetApiUrlContext;

export const ApiUrlProvider = ({ children }) => {
  function getApiUrlBaseEnv() {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
      return config.apiUrlDev;
    } else {
      return config.apiUrlProd;
    }
  }

  const apiUrl = getApiUrlBaseEnv();

  return (
    <GetApiUrlContext.Provider value={apiUrl}>
      {children}
    </GetApiUrlContext.Provider>
  );
};
