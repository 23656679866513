import { useTranslation } from "react-i18next";

export default function useContactInputs() {
  const { t } = useTranslation();
  const contacts_inputs = [
    {
      id: "contact_full_name",
      name: "contact_full_name",
      type: "text",
      placeholder: t("contact_full_name"),
      label: t("contact_full_name"),
      label_classname: "text-primary",
      errorMessage: t("errors.error_contact_full_name"),
      required: true,
    },
    {
      id: "contact_email",
      name: "contact_email",
      type: "email",
      placeholder: t("email"),
      label: t("email"),
      errorMessage: t("errors.error_contact_email"),
      required: true,
    },
    {
      id: "contact_phone",
      name: "contact_phone",
      type: "text",
      placeholder: t("phone"),
      label: t("phone"),
      errorMessage: t("errors.error_contact_phone"),
      required: true,
    },
    {
      id: "role_title",
      name: "role_title",
      type: "text",
      placeholder: t("role_title"),
      label: t("role_title"),
      errorMessage: t("errors.error_contact_role_title"),
      required: true,
    },
    {
      id: "contact_notes",
      name: "contact_notes",
      type: "text",
      placeholder: t("notes"),
      label: t("notes"),
      errorMessage: t("errors.error_contact_notes"),
    },
  ];
  return contacts_inputs;
}
