import React from "react";
import Tippy from "@tippyjs/react";
import "./buttonIcon.css";
const ButtonIcon = ({ path, classes, tippy_content, onclick, ...others }) => {
  return (
    <Tippy
      {...others}
      theme="light"
      maxWidth="100%"
      maxHeight="100%"
      placement="top-start"
      content={tippy_content}
    >
      <i onClick={onclick} role="button" className={classes}></i>
    </Tippy>
  );
};

export default ButtonIcon;
