import React, { useContext, useState } from "react";
import GetApiUrlContext from "../context/GetApiUrlContext";
import GlobeIconMenu from "./common/globeIconMenu";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import Input from "./common/input";
import Button from "./common/button";
import AuthContext from "../context/AuthContext";

function LoginForm(props) {
  // document.body.style = "background: whitesmoke;";
  const apiUrl = useContext(GetApiUrlContext);
  let { loginUser, company } = useContext(AuthContext);
  let [loginResponse, setLoginResponse] = useState({});
  const [showPasswordEye, setShowPasswordEye] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const { t } = useTranslation();
  function isPromise(p) {
    if (typeof p === "object" && typeof p.then === "function") {
      return true;
    }

    return false;
  }

  let handleSubmit = (e) => {
    let response = loginUser(e);
    setLoginResponse(isPromise(response));
  };

  let togglePasswordEye = () => {
    setShowPasswordEye(!showPasswordEye);
  };

  let togglePassword = () => {
    setShowPassword(!showPassword);
  };

  let currentComapny =
    localStorage.getItem("currentCompany") && localStorage.getItem("companies")
      ? JSON.parse(localStorage.getItem("currentCompany"))
      : company;

  return (
    <div>
      <div style={{ height: "130px" }}></div>
      <div
        className="container login-container"
        style={{ backgroundColor: "white" }}
      >
        <div className="pt-2">
          <GlobeIconMenu />
        </div>

        <div>
          <h2 className="d-flex justify-content-center">
            <img
              className="mt-5 login-logo"
              src={`${apiUrl}/${currentComapny.comapny_logo}`}
              alt="logo"
            />
          </h2>
          <div className="d-flex justify-content-center pt-1 mb-3">
            {/* <h2 className="text-success">Login</h2> */}
          </div>
        </div>
        <div className="justify-content-center">
          <form onSubmit={handleSubmit}>
            <Input name="username" label={t("email")} />
            <Input
              onClick={() => togglePasswordEye()}
              type={showPassword ? "text" : "password"}
              name="password"
              label={t("password")}
              autoComplete="on"
            />

            {i18next.language === "en" && showPasswordEye && (
              <i
                className={
                  showPassword
                    ? "fa fa-eye-slash fa-lg field-icon-eng"
                    : "fa fa-eye fa-lg field-icon-eng"
                }
                onClick={() => {
                  togglePassword();
                }}
              ></i>
            )}
            {i18next.language === "he" && showPasswordEye && (
              <i
                className={
                  showPassword
                    ? "fa fa-eye-slash fa-lg field-icon-heb"
                    : "fa fa-eye fa-lg field-icon-heb"
                }
                onClick={() => {
                  togglePassword();
                }}
              ></i>
            )}

            <div className="text-center mt-5 d-grid">
              <Button
                className="btn btn-login btn-lg btn-no-radius"
                type="submit"
                label={t("login")}
              />
            </div>
          </form>
        </div>
        {loginResponse === true && (
          <div>
            <br />
            <span style={{ fontSize: "15px", padding: "50px", color: "red" }}>
              {t("errors.error_incorrect_email_or_password")}
            </span>
          </div>
        )}
      </div>
    </div>
  );
}

export default LoginForm;
