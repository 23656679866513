import React from "react";
import { useState } from "react";
import "./input.css";
const FileInput = ({
  label,
  file_label,
  placeholder,
  errorMessage,
  label_classname,
  type,
  onChange,
  ...rest
}) => {
  const [fileName, setFileName] = useState(file_label);

  const handleFileChange = (e) => {
    console.log(`Selected file - ${e.target.files[0].name}`);
    setFileName(e.target.files[0].name);
    onChange(e);
  };

  return (
    <div className="form-group ">
      <div className="mt-2">
        <label>
          <u>{label}</u>
        </label>
        &nbsp;
        <label className="btn btn-secondary" htmlFor="file">
          {fileName}
        </label>
      </div>

      <input
        {...rest}
        onChange={handleFileChange}
        key="file"
        type="file"
        name="file"
        placeholder={placeholder}
        id="file"
        className="form-control formInput"
      />
    </div>
  );
};

export default FileInput;
