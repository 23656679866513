import { Route, Redirect, useHistory } from "react-router-dom";

import React, { useContext } from "react";
import AuthContext from "./../context/AuthContext";

const PrivateRoute = ({ children, ...rest }) => {
  let history = useHistory();
  let { user } = useContext(AuthContext);

  return (
    <Route {...rest}>
      {!user && !history.location.pathname.includes ? (
        <Redirect to="/login" />
      ) : (
        children
      )}
    </Route>
  );
};

// <Route {...rest}>
//   {!user &&
//   !history.location.pathname.includes("/tickets/createTicket/") ? (
//     <Redirect to="/login" />
//   ) : (
//     children
//   )}
// </Route>;

export default PrivateRoute;
