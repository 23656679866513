import React, { useContext, useState } from "react";
import GetApiUrlContext from "../../context/GetApiUrlContext";
import useContactInputs from "./useContactInputs";
import { useTranslation } from "react-i18next";
import Input from ".././common/input";
import Button from "../common/button";
import useAxios from "../../utils/useAxios";
import CustomAlert from "../common/customAlert";

const CreateContact = (props) => {
  const apiUrl = useContext(GetApiUrlContext);
  const { customer_id, lead_id } = props;
  const [showAlert, setShowAlert] = useState(false);
  const contactObj = {
    contact_full_name: "",
    contact_email: "",
    contact_phone: "",
    role_title: "",
    contact_notes: "",
  };
  const [contact, setContact] = useState(contactObj);

  const axiosAuth = useAxios();

  const handleChange = (e) => {
    setContact((values) => ({ ...values, [e.target.name]: e.target.value }));
  };

  const createContact = async (e) => {
    e.preventDefault();
    let connectionString;
    if (customer_id)
      connectionString = `${apiUrl}/contacts/?customer_id=${customer_id}`;
    else if (lead_id)
      connectionString = `${apiUrl}/contacts/?lead_id=${lead_id}`;
    let response = await axiosAuth.post(connectionString, contact);
    setShowAlert(true);
    setContact(contactObj);
    console.log(response.data);
  };

  const { t } = useTranslation();

  return (
    <React.Fragment>
      <div className="div-contact mb-5 ">
        <CustomAlert
          dialog={t("alerts.contact_created")}
          show={showAlert}
          setShow={setShowAlert}
        />

        <form onSubmit={createContact}>
          {useContactInputs().map((input) => (
            <Input
              label_classname={input.label_classname}
              key={input.id}
              {...input}
              value={contact[input.name] || ""}
              onChange={handleChange}
            />
          ))}
          <div className="text-center mt-3 d-grid gap-2">
            <Button
              id="createContact"
              type="submit"
              className="btn btn-success"
              label={t("create_contact")}
            />
          </div>
        </form>
      </div>
    </React.Fragment>
  );
};

export default CreateContact;
