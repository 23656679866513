import React, { useContext, useState, useEffect } from "react";
import GetApiUrlContext from "../../context/GetApiUrlContext";
import Pagination from "../common/pagination";
import { paginate } from "../../utils/paginate";
import _ from "lodash";
import useAxios from "../../utils/useAxios";
import Navbar from "../navbar";
import EmployeesTable from "./employeesTable";
import Button from "../common/button";
import { useTranslation } from "react-i18next";
import CreateEmployee from "./createEmployee";

const Employees = (props) => {
  const apiUrl = useContext(GetApiUrlContext);
  const [employees, setEmployees] = useState([]);
  const [showNewEmployee, setShowNewEmployee] = useState(false);
  const [search, setSearch] = useState("");
  const [pageProps, setPageProps] = useState({ currentPage: 1, pageSize: 10 });
  const [sortColumn, setSortColumn] = useState({ path: "id", order: "desc" });
  const { t } = useTranslation();

  let axiosAuth = useAxios();

  useEffect(() => {
    getEmployees();
  }, []);

  let currentComapny = JSON.parse(localStorage.getItem("currentCompany"));

  let getEmployees = async () => {
    let response = await axiosAuth.get(
      `${apiUrl}/employees/${currentComapny.id}`
    );
    if (response.status === 200) {
      setEmployees(response.data);
    }
  };

  const handleDelete = async (employee_id) => {
    let response = await axiosAuth.delete(
      `${apiUrl}/employees/${currentComapny.id}/${employee_id}`
    );
    if (response.status === 204) {
      getEmployees();
    }
  };

  const handleSetShowNewEmployee = () => {
    setShowNewEmployee((showNewEmployee) => !showNewEmployee);
  };

  let getPageData = (allEmployees) => {
    let filtered = allEmployees;

    if (search) {
      let filtered1 = filtered.filter((x) =>
        x.name.toLowerCase().includes(search.toLowerCase())
      );
      let filtered2 = filtered.filter((x) =>
        x.email.toLowerCase().includes(search.toLowerCase())
      );

      let filteredUniqSet = new Set([...filtered1, ...filtered2]);
      filtered = [...filteredUniqSet];
    }

    let sorted = _.orderBy(
      filtered,
      ["is_light", sortColumn.path],
      ["desc", sortColumn.order]
    );

    // sorted = sorted.sort()

    const employees_paginated = paginate(
      sorted,
      pageProps.currentPage,
      pageProps.pageSize
    );

    return { totalCount: filtered.length, data: employees_paginated };
  };

  const { totalCount, data: employeesPaginated } = getPageData(employees);

  return (
    <React.Fragment>
      <Navbar />
      <div className="container">
        <div className="text-center text-primary mt-3">
          <h2>{t("employees")}</h2>
        </div>
        <div className="row">
          <div className="col-4 align-self-center">
            <input
              onChange={(e) => {
                setSearch(e.target.value);
              }}
              className="form-control"
              type="text"
              placeholder={t("search_employee")}
              value={search}
            ></input>
          </div>
          <div className="col">
            <Button
              label={t("add_new_employee")}
              onClick={handleSetShowNewEmployee}
              className="btn btn-outline-primary"
            />
          </div>
        </div>
        <div className="mt-3">
          {showNewEmployee && <CreateEmployee company_id={currentComapny.id} />}
          <EmployeesTable
            handleDelete={handleDelete}
            getEmployees={getEmployees}
            employees={employeesPaginated}
            sortColumn={sortColumn}
            onSort={(sortColumn) => setSortColumn(sortColumn)}
          />
          <Pagination
            itemsCount={totalCount}
            pageSize={pageProps.pageSize}
            currentPage={pageProps.currentPage}
            onPageChange={(page) => {
              setPageProps({ ...pageProps, currentPage: page });
            }}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default Employees;
