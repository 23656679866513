import React, { Component } from "react";
import uuid from "react-uuid";
import _ from "lodash";
import "./table.css";
class TableBody extends Component {
  renderCell = (item, column) => {
    if (column.content) return column.content(item);
    let sortPath = _.get(item, column.path);

    return sortPath;
  };
  createKey = (item, column) => {
    return item.id + (column.path || column.key) || uuid;
  };

  render() {
    const { data, columns } = this.props;
    return (
      <tbody>
        {data.map((item) => (
          <tr is_light={String(item.is_light)} key={item.id}>
            {columns.map((column) => (
              // <td key={item._id + (column.path || column.key)}>
              <td
                style={{ textAlign: "center" }}
                key={this.createKey(item, column)}
              >
                {this.renderCell(item, column)}
              </td>
              //   <td>{item[column.path]}</td>  --dont work for nested objects
              // instead we ill use loadash
            ))}
          </tr>
        ))}
      </tbody>
    );
  }
}

export default TableBody;
