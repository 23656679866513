import React from "react";
import { useState } from "react";
import "./input.css";
const Input = ({
  name,
  label,
  file_label,
  placeholder,
  errorMessage,
  label_classname,
  type,
  className,
  ...rest
}) => {
  const [focused, setFocused] = useState(false);

  let handleFocused = (e) => {
    setFocused(true);
  };
  return (
    <div className="form-group ">
      {label_classname && (
        <label className={label_classname} htmlFor={name}>
          {label}
        </label>
      )}
      {!label_classname && <label htmlFor={name}>{label}</label>}

      <input
        {...rest}
        type={type}
        name={name}
        placeholder={placeholder}
        id={name}
        className={className ? className : "form-control formInput"}
        onBlur={handleFocused}
        onFocus={() => {
          rest.name === "confirmPassword" && setFocused(true);
        }}
        focused={focused.toString()}
      />
      <span className="alertMessage">{errorMessage}</span>
    </div>
  );
};

export default Input;
