import React from "react";
import { useTranslation } from "react-i18next";
import Table from "../common/table";
import uuid from "react-uuid";

const TicketsCustomerReportTable = ({ data }) => {
  const { t } = useTranslation();
  let dataTable = [];
  data.labels.forEach((month, index) => {
    dataTable.unshift({
      month: month,
      count: data.datasets[0].data[index],
      id: month + index,
    });
  });
  let columns = [
    { path: "month", label: t("month"), key: "id" },
    { path: "count", label: t("tickets_count"), key: "id" },
  ];
  return (
    <React.Fragment>
      <Table columns={columns} data={dataTable} />
    </React.Fragment>
  );
};
export default TicketsCustomerReportTable;
