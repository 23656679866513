import React, { useContext, useState, useEffect } from "react";
import GetApiUrlContext from "../../context/GetApiUrlContext";
import Pagination from "../common/pagination";
import { paginate } from "../../utils/paginate";
import _ from "lodash";
import useAxios from "../../utils/useAxios";
import Navbar from "../navbar";
import CustomersTable from "./customersTable";
import Button from "../common/button";
import { useTranslation } from "react-i18next";
import CreateCustomer from "./createCustomer";

const Customers = (props) => {
  const apiUrl = useContext(GetApiUrlContext);
  const [customers, setCustomers] = useState([]);
  const [showNewCustomer, setShowNewCustomer] = useState(false);
  const [search, setSearch] = useState("");
  const [pageProps, setPageProps] = useState({ currentPage: 1, pageSize: 10 });
  const [sortColumn, setSortColumn] = useState({ path: "id", order: "desc" });
  const { t } = useTranslation();

  let axiosAuth = useAxios();

  useEffect(() => {
    getCustomers();
  }, []);

  let currentComapny = JSON.parse(localStorage.getItem("currentCompany"));

  let getCustomers = async () => {
    // if (localStorage.getItem("currentCompany")) {
    //   currentComapny = JSON.parse(localStorage.getItem("currentCompany"));
    // }
    let response = await axiosAuth.get(
      `${apiUrl}/customers/${currentComapny.id}`
    );
    if (response.status === 200) {
      setCustomers(response.data);
    }
  };

  const handleDelete = async (customer_id) => {
    let response = await axiosAuth.delete(
      `${apiUrl}/customers/${currentComapny.id}/${customer_id}`
    );
    if (response.status === 204) {
      getCustomers();
    }
  };

  const handleShowNewCustomer = () => {
    setShowNewCustomer((showNewCustomer) => !showNewCustomer);
  };

  let getPageData = (allCustomers) => {
    let filtered = allCustomers;

    if (search) {
      let filtered1 = filtered.filter((x) =>
        x.name.toLowerCase().includes(search.toLowerCase())
      );
      let filtered2 = filtered.filter((x) =>
        x.email.toLowerCase().includes(search.toLowerCase())
      );

      let filteredUniqSet = new Set([...filtered1, ...filtered2]);
      filtered = [...filteredUniqSet];
    }

    let sorted = _.orderBy(
      filtered,
      ["is_light", sortColumn.path],
      ["desc", sortColumn.order]
    );

    // sorted = sorted.sort()

    const tickets = paginate(sorted, pageProps.currentPage, pageProps.pageSize);

    return { totalCount: filtered.length, data: tickets };
  };

  const { totalCount, data: customersPaginated } = getPageData(customers);

  return (
    <React.Fragment>
      <Navbar />
      <div className="container">
        <div className="text-center text-primary mt-3">
          <h2>{t("customers")}</h2>
        </div>
        <div className="row">
          <div className="col-4 align-self-center">
            <input
              onChange={(e) => {
                setSearch(e.target.value);
              }}
              className="form-control"
              type="text"
              placeholder={t("search_customer")}
              value={search}
            ></input>
          </div>
          <div className="col">
            <Button
              className="btn btn-outline-primary"
              label={t("add_new_customer")}
              onClick={handleShowNewCustomer}
            />
          </div>
        </div>

        <div className="mt-3">
          {showNewCustomer && <CreateCustomer company_id={currentComapny.id} />}
          <CustomersTable
            handleDelete={handleDelete}
            getCustomers={getCustomers}
            customers={customersPaginated}
            sortColumn={sortColumn}
            onSort={(sortColumn) => setSortColumn(sortColumn)}
          />
          <Pagination
            itemsCount={totalCount}
            pageSize={pageProps.pageSize}
            currentPage={pageProps.currentPage}
            onPageChange={(page) => {
              setPageProps({ ...pageProps, currentPage: page });
            }}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default Customers;
