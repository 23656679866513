import React from "react";

import "font-awesome/css/font-awesome.css";
import "./edit.css";
import { Link } from "react-router-dom";
import Tippy from "@tippyjs/react";
import "tippy.js/themes/light.css";
const Edit = (props) => {
  const { path, tippy_content, ...others } = props;
  let classes = "fa fa-pencil-square-o";

  return (
    <Tippy
      {...others}
      theme="light"
      maxWidth="100%"
      maxHeight="100%"
      placement="top-start"
      content={tippy_content}
    >
      <Link to={path}>
        <i className={classes}></i>
      </Link>
    </Tippy>
  );
};

export default Edit;
