import React, { useState, useEffect, useContext } from "react";
import AuthContext from "../../context/AuthContext";
import GetApiUrlContext from "../../context/GetApiUrlContext";
import useAxios from "../../utils/useAxios";
import { convertToLocalDate } from "../../utils/convertToLocalDate";
import Navbar from "../navbar";
import Button from "../common/button";
import TicketsCustomersReportTable from "./ticketsCustomersReportTable";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import DatePicker, { registerLocale } from "react-datepicker";
import en from "date-fns/locale/en-US";
import he from "date-fns/locale/he";
import {
  Chart as ChartJS,
  ArcElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Pie } from "react-chartjs-2";

registerLocale("he", he);
registerLocale("en", en);
ChartJS.register(
  ArcElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const TicketsCustomersReport = () => {
  let initStart = () => {
    let date = new Date();
    return new Date(date.getFullYear(), date.getMonth() - 1, 1);
  };
  let apiUrl = useContext(GetApiUrlContext);
  let { company } = useContext(AuthContext);
  let axiosAuth = useAxios();
  const { t } = useTranslation();
  let [start, setStart] = useState(initStart());
  let [end, setEnd] = useState(new Date());
  let [labels, setLabeles] = useState([]);
  let [counts, setCounts] = useState([]);
  let [dataTabale, setDataTable] = useState([]);

  let dataObj = {
    labels: labels,
    datasets: [
      {
        label: "My First dataset",
        backgroundColor: [
          "#FF4500",
          "#ff6384",
          "#36a2eb",
          "#cc65fe",
          "#ffce56",
          "#9ACD32",
          "#F4A460",
          "#48D1CC",
          "#FFB6C1",
          "#FFEFD5",
        ],
        borderColor: "rgb(0,0,255)",
        data: counts,
      },
    ],
  };

  // let getByCustomTime = () => {
  //   if (start !== undefined && end !== undefined && company) {
  //     fetchData(start, end);
  //   }
  // };

  let fetchData = async (startTime, endTime) => {
    let connection = `${apiUrl}/reports/tickets_counts_by_customers/${company.id}/?`;
    connection += `&start=${convertToLocalDate(
      new Date(startTime).toLocaleDateString()
    )}`;
    connection += `&end=${convertToLocalDate(
      new Date(endTime).toLocaleDateString()
    )}`;
    let response = await axiosAuth.get(connection);
    let sotrtedResponse = response.data
      .sort(function (a, b) {
        return b.count - a.count;
      })
      .slice(0, 10);

    setLabeles(sotrtedResponse.map(({ customer_name }) => customer_name));
    setCounts(sotrtedResponse.map(({ count }) => count));
    setDataTable(response.data);
  };

  useEffect(() => {
    fetchData(start, end);
  }, []);

  return (
    <React.Fragment>
      <Navbar />
      <div className="container">
        <div className="text-center">
          <h2>{t("count_tickets_per_each_customer_by_time")}</h2>
        </div>
        <div className="text-center">
          <div className="row mt-4 justify-content-center">
            <div className="col-xl-3">
              <label htmlFor="start">{t("start_date")}</label>
              <DatePicker
                locale={i18next.language}
                dateFormat="dd/MM/yyyy"
                // placeholderText={t("start_date")}
                className="form-control"
                selected={start}
                onChange={(date) => setStart(date)}
              />
            </div>
            <div className="col-xl-3">
              <label htmlFor="end">{t("end_date")}</label>
              <DatePicker
                locale={i18next.language}
                dateFormat="dd/MM/yyyy"
                // placeholderText={t("end_date")}
                className="form-control"
                selected={end}
                onChange={(date) => setEnd(date)}
              />
            </div>

            {/* <div className="col-xl-2 d-grid d-xl-block"></div> */}
            <div className="col-xl-2 d-grid d-xl-block mt-4">
              <Button
                onClick={() => fetchData(start, end)}
                label={t("filter_tickets")}
              />
            </div>
          </div>
        </div>
        {dataTabale.length > 0 && (
          <div>
            <div
              className="text-center mt-4"
              style={{ width: "50%", marginLeft: "auto", marginRight: "auto" }}
              //the style is center the pie and change the width in the same time !!!
            >
              <Pie data={dataObj} />
            </div>
            <div>
              <TicketsCustomersReportTable data={dataTabale} />
            </div>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default TicketsCustomersReport;
