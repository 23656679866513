import React from "react";
import Modal from "react-bootstrap/Modal";
import "./customAlerts.css";
import Button from "./button";
import { useTranslation } from "react-i18next";

const CustomAlert = ({ title, dialog, setShow, show }) => {
  const { t } = useTranslation();
  const handleClick = () => {
    setShow(false);
    window.location.reload(false);
  };

  return (
    <Modal
      show={show}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{dialog}</Modal.Body>
      <Modal.Footer>
        {" "}
        <Button label={t("ok")} onClick={handleClick} variant="secondary">
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CustomAlert;
