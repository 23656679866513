import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import TicketToolTip from "./ticketTooltip";
import Table from "../common/table";
import Edit from "../common/edit";
import "font-awesome/css/font-awesome.css";
import LinkIcon from "../common/linkIcon";
import ButtonIcon from "../common/buttonIcon";
import TooltipIcon from "../common/tooltipIcon";
import AuthContext from "../../context/AuthContext";
import moment from "moment";
const TicketsTable = (props) => {
  const { tickets, onSort, sortColumn, handleJumpTicket, getTickets } = props;
  const { user } = useContext(AuthContext);
  const { t } = useTranslation();

  let getCurrentHandling = (
    startHandling,
    time_current_handling,
    time_total_handling
  ) => {
    let currentTime = new Date().toLocaleString("es-SV", {
      hour12: false,
    });
    let returnTime = "";
    if (startHandling !== null && time_current_handling === "") {
      let momentStartHandling = moment(startHandling, "dd/MM/yyyy HH:mm:ss");
      let momentCurentTime = moment(currentTime, "dd/MM/yyyy HH:mm:ss");
      const duration = moment.duration(
        momentCurentTime.diff(momentStartHandling)
      );

      returnTime = `${duration.days()}:${duration.hours()}:${duration.minutes()}:${duration.seconds()}`;
    } else if (time_current_handling !== null) {
      returnTime = time_current_handling;
    } else {
      returnTime = "";
    }

    return returnTime;
  };

  let columns = [
    { path: "id", label: t("ticket_number"), key: "ticketId" },
    { path: "customer_name", label: t("customer_name"), key: "customerName" },
    {
      path: "full_name",
      label: <span className="small_hide">{t("full_name")}</span>,
      key: "fullName",
      content: (ticket) => (
        <span className="small_hide">{ticket.full_name}</span>
      ),
    },
    {
      path: "phone",
      label: <span className="small_hide">{t("phone")}</span>,
      key: "phone",
      content: (ticket) => <span className="small_hide">{ticket.phone}</span>,
    },
    {
      path: "email",
      label: <span className="small_hide">{t("ticket_email")}</span>,
      key: "ticketEmail",
      content: (ticket) => <span className="small_hide">{ticket.email}</span>,
    },
    {
      path: "category",
      label: <span className="small_hide">{t("category")}</span>,
      key: "category",
      content: (ticket) => (
        <span className="small_hide">{ticket.category[t("name")]}</span>
      ),
    },
    {
      path: "ticket_time",
      label: <span className="small_hide">{t("ticket_time")}</span>,
      key: "ticketTime",
      content: (ticket) => (
        <span className="small_hide">{ticket.ticket_time}</span>
      ),
    },
    { path: "employee", label: t("support"), key: "employee" },
    {
      path: "status",
      label: t("status"),
      key: "staus",
      content: (ticket) => (
        <span className={`badge rounded-pill ${ticket.status.color}`}>
          {ticket.status[t("name")]}
        </span>
        // <span style={{ color: ticket.status.color, fontWeight: 700 }}>
        //   {ticket.status[t("name")]}
        // </span>
      ),
    },
    {
      path: "time_current_handling",
      label: (
        <span className="small_hide">
          {" "}
          <TooltipIcon
            className="fa fa-clock-o fa-lg text-info"
            tippy_content={t("current_handling_time")}
          />
        </span>
      ),
      // label: <span className="small_hide">{t("current_handling_time")}</span>,

      key: "currentHandlingTime",
      content: (ticket) => (
        <span className="small_hide">
          {getCurrentHandling(
            ticket.start_handling,
            ticket.time_current_handling,
            ticket.time_total_handling
          )}
        </span>
      ),
    },
    {
      path: "time_total_handling",
      label: (
        <span className="small_hide">
          {" "}
          <TooltipIcon
            className="fa fa-clock-o fa-lg text-success "
            tippy_content={t("total_handling_time")}
          />
        </span>
      ),
      // label: <span className="small_hide">{t("total_handling_time")}</span>,

      key: "totalHandlingTime",
      content: (ticket) => (
        <span className="small_hide">{ticket.time_total_handling}</span>
      ),
    },
    {
      path: "options",
      label: t("options"),
      key: "options",
      // content: (ticket) => <Edit onclick={() => this.props.onEdit(ticket)} />,
      content: (ticket) => (
        <React.Fragment>
          <Edit
            tippy_content={<TicketToolTip theme="light" ticket={ticket} />}
            path={`/tickets/editTicket/${ticket.company.id}/${ticket.id}`}
          />
          {user.role === "employee" && (
            <React.Fragment>
              &nbsp;&nbsp;&nbsp;
              <LinkIcon
                className="fa fa-forward"
                tippy_content={t("tooltips.move_ticket_to_company")}
                path={`/tickets/ForwoardTicket/${ticket.company.id}/${ticket.id}`}
              />
              {ticket.customer && (
                <span>
                  &nbsp;&nbsp;&nbsp;
                  <LinkIcon
                    className="fa fa-user-o"
                    tippy_content={t("tooltips.go_to_customer")}
                    path={`/customers/editCustomer/${ticket.company.id}/${ticket.customer.id}`}
                  />
                </span>
              )}
              &nbsp;&nbsp;&nbsp;
              <ButtonIcon
                classes="fa fa-history icon-color-blue"
                tippy_content={t("tooltips.show_tickets_history")}
                onclick={() => {
                  getTickets(
                    ticket.customer,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined
                  );
                }}
              />
              &nbsp;&nbsp;&nbsp;
              <ButtonIcon
                classes="fa fa-bell-o icon-color-blue"
                tippy_content={t("tooltips.jump_ticket")}
                onclick={() => {
                  handleJumpTicket(ticket.id, ticket.is_light);
                }}
              />
              {ticket.is_private && (
                <span>
                  &nbsp;&nbsp;&nbsp;
                  <LinkIcon
                    className="text-danger fa fa-eye-slash"
                    tippy_content={t("tooltips.hidden_ticket")}
                    path="#"
                  />
                </span>
              )}
            </React.Fragment>
          )}
          {ticket.hasFiles && (
            <span>
              &nbsp;&nbsp;&nbsp;
              <LinkIcon
                className="text-danger fa fa-paperclip"
                tippy_content={t("tooltips.atach_file")}
                path="#"
              />
            </span>
          )}
        </React.Fragment>
      ),
    },
  ];

  return (
    <Table
      columns={columns}
      data={tickets}
      sortColumn={sortColumn}
      onSort={onSort}
    />
  );
};

export default TicketsTable;
