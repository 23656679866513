const Button = ({
  onClick,
  label,
  id,
  type,
  className,
  onChange,
  disabled,
  ...other
}) => {
  if (!className) className = "btn btn-primary";
  return (
    <button
      onClick={onClick}
      id={id}
      key="label"
      //   disabled={this.validate()}
      className={className}
      type={type}
      disabled={disabled}
      {...other}
    >
      {label}
    </button>
  );
};

export default Button;
