import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import Table from "../common/table";
import Edit from ".././common/edit";
import ButtonIcon from "../common/buttonIcon";
import ConfirmAlert from "../common/confirmAlert";

const CustomersTable = (props) => {
  const { customers, onSort, sortColumn, handleDelete } = props;
  const [showConfirm, setShowConfirm] = useState(false);
  const { t } = useTranslation();
  const history = useHistory();
  let currentComapny = JSON.parse(localStorage.getItem("currentCompany"));

  let columns = [
    { path: "name", label: t("input_name"), key: "name" },
    { path: "email", label: t("email"), key: "email" },
    { path: "phone", label: t("phone"), key: "phone" },
    {
      path: "options",
      label: t("options"),
      key: "options",
      content: (customer) => (
        <div>
          <ConfirmAlert
            show={showConfirm}
            setShow={setShowConfirm}
            object_id={customer.id}
            handleClick={handleDelete}
          />
          <span></span>
          <span>
            <Edit
              classes="fa fa-user-o"
              tippy_content={t("tooltips.go_to_customer")}
              path={`/customers/editCustomer/${currentComapny.id}/${customer.id}`}
            />
          </span>
          &nbsp;&nbsp;&nbsp;
          <span>
            {/* need to update to react-router-6 and after it i can pass customer params to tickets */}
            {/* <ButtonIcon
              classes="fa fa-ticket"
              tippy_content="Go To Customer Tickets"
              onclick={() =>
                history.push(`/tickets/${customer.id}/${customer.name}`)
              }
            /> */}
          </span>
          &nbsp;&nbsp;&nbsp;
          <span>
            <ButtonIcon
              classes="fa fa-minus-circle text-danger"
              tippy_content={t("tooltips.delete_customer")}
              onclick={() => {
                setShowConfirm(true);
              }}
            />
          </span>
        </div>
      ),
    },
  ];
  return (
    <Table
      columns={columns}
      data={customers}
      sortColumn={sortColumn}
      onSort={onSort}
    />
  );
};

export default CustomersTable;
