import React, { useContext, useEffect, useState } from "react";
import AuthContext from "../../context/AuthContext";
import GetApiUrlContext from "../../context/GetApiUrlContext";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import moment from "moment";
import Navbar from "../navbar";
import Select from "react-select";
import axios from "axios";
import useAxios from "../../utils/useAxios";
import Input from "../common/input";
import Output from "../common/output";
import TextArea from "../common/textArea";
import Button from "../common/button";
import FileInput from "../common/fileInput";
import TicketFiles from "./ticketFiles";
import CustomAlert from "../common/customAlert";

const EditTicket = () => {
  const apiUrl = useContext(GetApiUrlContext);
  const params = useParams();

  const { t } = useTranslation();
  const history = useHistory();
  const [updateCustomer, setUpdateCustomer] = useState(false);
  const [ticket, setTicket] = useState({
    id: "",
    files: [],
    company: {},
    full_name: "",
    email: "",
    phone: "",
    ticket_time: "",
    time_current_handling: "",
    time_total_handling: "",
    customer_name: "",
    assign_by: "",
    description: "",
    solution: "",
    notes: "",
    is_light: false,
    is_private: false,
    customer: {},
    employee: {},
    status: {},
    category: {},
  });
  const [oldTicket, setOldTicket] = useState({});
  const [selectedFile, setSelectedFile] = useState(null);
  const [categories, setCategories] = useState();
  const [employees, setEmployees] = useState();
  const [customers, setCustomers] = useState();
  const [statuses, setStatuses] = useState();
  const [showAlert, setShowAlert] = useState(false);
  const [showSelectsAlerts, setShowSelectAlerts] = useState(false);
  let { user } = useContext(AuthContext);

  let axiosAuth = useAxios();

  useEffect(() => {
    getTicket(params.id, params.company_id);
    getCategories();
    getStatuses();
    getEmployees();
    getCustomers();
    return () => {
      setCategories({});
      setEmployees({});
      setCustomers({});
      setStatuses({});
    };
  }, []);

  const handleChange = (e) => {
    setTicket((values) => ({ ...values, [e.target.name]: e.target.value }));
  };
  const handleCheckBoxChange = (e) => {
    setTicket((values) => ({ ...values, [e.target.name]: e.target.checked }));
  };

  const handleTextAreaClick = (e) => {
    let currentTime = new Date().toLocaleString("es-SV", {
      hour12: false,
    });
    let momentCurentTime = moment(currentTime, "DD/MM/yyyy HH:mm:ss").format(
      "DD/MM/yyyy HH:mm:ss"
    );

    if (ticket[e.target.name] === null) {
      setTicket((values) => ({
        ...values,
        [e.target.name]: `${momentCurentTime} - ${user.name} : `,
      }));
    } else if (
      ticket[e.target.name] !== null &&
      !e.target.name.endsWith("\n") &&
      oldTicket[e.target.name] === ticket[e.target.name]
    ) {
      setTicket((values) => ({
        ...values,
        [e.target.name]: `${ticket[e.target.name]}\n${momentCurentTime} - ${
          user.name
        } : \t`,
      }));
    }
  };

  // const handleTextAreaClick = (e) => {
  //   if (ticket[e.target.name] === null) {
  //     setTicket((values) => ({
  //       ...values,
  //       [e.target.name]: `${new Date().toLocaleString("IL", {
  //         hour12: false,
  //       })} - ${user.name} : `,
  //     }));
  //     // let momentCurentTime = moment(currentTime, "dd/MM/yyyy HH:mm:ss");
  //   } else if (
  //     ticket[e.target.name] === null &&
  //     !e.target.name.endsWith("\n")
  //   ) {
  //     setTicket((values) => ({
  //       ...values,
  //       [e.target.name]: `${
  //         ticket[e.target.name]
  //       } \n ${new Date().toLocaleString("IL", {
  //         hour12: false,
  //       })} - ${user.name} : \n`,
  //     }));
  //   }
  // };

  const handleUpdateCustomer = () => {
    setUpdateCustomer((updateCustomer) => !updateCustomer);
  };

  let getCategories = async () => {
    let response = await axios.get(`${apiUrl}/categories/${params.company_id}`);
    if (response.status === 200) {
      setCategories(response.data);
    }
  };

  let getStatuses = async () => {
    let response = await axiosAuth.get(
      `${apiUrl}/tickets/status_list/${params.company_id}`
    );
    if (response.status === 200) {
      setStatuses(response.data);
    }
  };

  let getCustomers = async () => {
    if (user.role === "employee") {
      let response = await axiosAuth.get(
        `${apiUrl}/customers/${params.company_id}`
      );
      if (response.status === 200) {
        setCustomers(response.data);
      }
    }
  };
  let getEmployees = async () => {
    if (user.role === "employee") {
      let response = await axiosAuth.get(
        `${apiUrl}/employees/${params.company_id}`
      );
      if (response.status === 200) {
        setEmployees(response.data);
      }
    }
  };

  let getTicket = async (id, company_id) => {
    if (id) {
      let response = await axiosAuth.get(
        `${apiUrl}/tickets/${company_id}/${id}`
      );
      setTicket(response.data);
      setOldTicket(response.data);
    }
  };

  const handleFileInput = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  let editTicket = async (e) => {
    e.preventDefault();

    if (!ticket.employee || !ticket.customer) {
      setShowSelectAlerts(true);
    } else {
      let ticketWithFile = new FormData();
      ticketWithFile.append("id", ticket.id);
      ticketWithFile.append("company", ticket.company);
      ticketWithFile.append("full_name", ticket.full_name);
      ticketWithFile.append("customer_name", ticket.customer_name);
      ticketWithFile.append("email", ticket.email);
      if (ticket.user) ticketWithFile.append("assign_by", ticket.user.name);
      ticketWithFile.append("phone", ticket.phone);
      ticketWithFile.append("description", ticket.description);
      ticketWithFile.append("ticket_time", ticket.ticket_time);
      ticketWithFile.append(
        "time_current_handling",
        ticket.time_current_handling
      );
      ticketWithFile.append("time_total_handling", ticket.time_total_handling);
      if (ticket.notes) ticketWithFile.append("notes", ticket.notes);
      ticketWithFile.append("is_light", ticket.is_light);
      ticketWithFile.append("is_private", ticket.is_private);
      if (ticket.solution) ticketWithFile.append("solution", ticket.solution);
      if (ticket.status) ticketWithFile.append("status", ticket.status.id);
      if (ticket.customer)
        ticketWithFile.append("customer", ticket.customer.id);
      if (ticket.employee)
        ticketWithFile.append("employee", ticket.employee.id);
      if (ticket.category)
        ticketWithFile.append("category", ticket.category.id);

      ticketWithFile.append("file", selectedFile);

      let connectionString = `${apiUrl}/tickets/${params.company_id}/${ticket.id}/?updateCustomer=${updateCustomer}`;
      await axiosAuth.put(connectionString, ticketWithFile);
      getTicket(ticket.id, params.company_id);
      setShowAlert(true);
    }
  };

  let editTicketAndExit = async (e) => {
    await editTicket(e);
    if (ticket.employee && ticket.customer) {
      history.push("/tickets");
    }
  };

  return (
    <React.Fragment>
      <Navbar />
      <CustomAlert
        dialog={t("alerts.ticket_updated")}
        setShow={setShowAlert}
        show={showAlert}
      />
      <div className="container container-forms">
        <h2 className="text-secondary">
          {t("edit_ticket")}: <span className="text-primary">{ticket.id}</span>
        </h2>
        <form>
          <div className="row">
            <div className="col-md-6">
              <Input
                onChange={handleChange}
                name="full_name"
                label={t("full_name")}
                value={ticket.full_name}
              />
            </div>

            <div className="col-md-6">
              <Input
                onChange={handleChange}
                name="customer_name"
                label={t("customer_name")}
                value={ticket.customer_name}
              />
            </div>
            <div className="col-md-6">
              <Input
                onChange={handleChange}
                name="email"
                label={t("email")}
                value={ticket.email}
              />
            </div>

            <div className="col-md-6">
              <Input
                onChange={handleChange}
                name="phone"
                label={t("phone")}
                value={ticket.phone}
              />
            </div>
            <div className="col mt-3 text-center">
              <span>
                <h5>
                  <div className="col-sm-12 text-secondary">
                    <u>{t("ticket_time")}:</u>
                  </div>
                  <div className="col-sm-12  mt-1 text-primary">
                    {" "}
                    {ticket.ticket_time}
                  </div>
                </h5>
              </span>
            </div>
          </div>

          {ticket.customer && (
            <div className="row">
              <div className="col">
                <Output
                  label={t("customer_phone")}
                  value={ticket.customer.phone}
                />
              </div>
              <div className="col">
                <Output
                  label={t("customer_email")}
                  value={ticket.customer.email}
                />
              </div>
            </div>
          )}
          <div className="row">
            {ticket.assign_by && (
              <div className="col">
                <Output
                  onChange={handleChange}
                  name="assign_by"
                  label="Assign By"
                  value={ticket.assign_by}
                />
              </div>
            )}

            {ticket.time_current_handling && (
              <div className="col">
                <Output
                  label={t("current_handling_time")}
                  value={ticket.time_current_handling.split(".")[0]}
                />
              </div>
            )}
            {ticket.time_total_handling && (
              <div className="col">
                <Output
                  label={t("total_handling_time")}
                  value={ticket.time_total_handling.split(".")[0]}
                />
              </div>
            )}
          </div>
          <div>
            <div className="form-outline">
              <TextArea
                border={true}
                dir="rtl"
                onChange={handleChange}
                name="description"
                value={ticket.description}
                label={t("description")}
              />
            </div>
            <div>
              <TextArea
                border={true}
                dir="rtl"
                onClick={handleTextAreaClick}
                onChange={handleChange}
                name="solution"
                value={ticket.solution}
                label={t("solution")}
              />
            </div>
            {user.role === "employee" && (
              <div>
                <TextArea
                  border={true}
                  dir="rtl"
                  onClick={handleTextAreaClick}
                  onChange={handleChange}
                  name="notes"
                  value={ticket.notes}
                  label={t("notes")}
                />
              </div>
            )}
          </div>
          <div className="row">
            {user.role === "employee" && (
              <React.Fragment>
                <div className="col-md-6">
                  <label htmlFor="employee">{t("employee")}</label>
                  <Select
                    required={true}
                    className="text-center"
                    inputId="employee"
                    options={employees}
                    value={ticket.employee}
                    getOptionLabel={(options) => options["name"]}
                    getOptionValue={(options) => options["id"]}
                    onChange={(e) => setTicket({ ...ticket, employee: e })}
                  />
                  {!ticket.employee && showSelectsAlerts === true && (
                    <span style={{ fontSize: 15 }} className="text-danger">
                      {t("errors.error_require_employee")}
                    </span>
                  )}
                </div>

                <div className="col-md-6">
                  <label htmlFor="customer">{t("customer")}</label>
                  <Select
                    required
                    className="text-center"
                    inputId="customer"
                    options={customers}
                    value={ticket.customer}
                    getOptionLabel={(options) => options["name"]}
                    getOptionValue={(options) => options["id"]}
                    onChange={(e) => setTicket({ ...ticket, customer: e })}
                  />
                  {!ticket.customer && showSelectsAlerts === true && (
                    <span style={{ fontSize: 15 }} className="text-danger">
                      {t("errors.error_require_customer")}
                    </span>
                  )}
                </div>
              </React.Fragment>
            )}

            <div className="col-md-6">
              <label htmlFor="category">{t("category")}</label>
              <Select
                required
                className="text-center"
                inputId="category"
                // placeholder="Category"
                options={categories}
                value={ticket.category}
                getOptionLabel={(options) => options["name"]}
                getOptionValue={(options) => options["id"]}
                onChange={(e) => setTicket({ ...ticket, category: e })}
              />
            </div>
            <div className="col-md-6">
              <label htmlFor="status">{t("status")}</label>
              <Select
                className="text-center"
                required
                inputId="status"
                options={statuses}
                value={ticket.status}
                getOptionLabel={(options) => options["name"]}
                getOptionValue={(options) => options["id"]}
                onChange={(e) => setTicket({ ...ticket, status: e })}
              />
            </div>
          </div>
          {user && user.role === "employee" && (
            <div className="row mt-2 mb-2">
              <div className="col-md-4">
                <label className="text-warning">
                  {t("set_as_private")} ?{" "}
                  <input
                    checked={ticket.is_private}
                    onChange={handleCheckBoxChange}
                    type="checkbox"
                    name="is_private"
                    id="setPrivate"
                  />
                </label>
              </div>
              <div className="col-md-4">
                <label className="text-warning">
                  {t("light_ticket")} ?{" "}
                  <input
                    checked={ticket.is_light}
                    onChange={handleCheckBoxChange}
                    name="is_light"
                    type="checkbox"
                  />
                </label>
              </div>
              <div className="col-md-4">
                <label className="text-danger">
                  {t("update_customer")} ?{" "}
                  <input
                    checked={updateCustomer}
                    onChange={handleUpdateCustomer}
                    name="update_customer"
                    type="checkbox"
                  />
                </label>
              </div>
            </div>
          )}
          <div>
            <div className="d-flex justify-content-center">
              <FileInput
                label={t("add_files")}
                file_label={t("choose_files")}
                onChange={handleFileInput}
              />
            </div>
            <TicketFiles company_id={params.company_id} files={ticket.files} />

            <div className="row text-center mt-2">
              <div>
                <Button id="editTicket" onClick={editTicket} label="Update" />{" "}
                <div className="d-block d-sm-none mt-2"></div>
                <Button
                  id="editSendExit"
                  onClick={editTicketAndExit}
                  name="editSendExit"
                  label="Update & Exit"
                />
              </div>
            </div>
          </div>
        </form>
      </div>
    </React.Fragment>
  );
};
export default EditTicket;
