import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import AuthContext from "../../context/AuthContext";
import TextArea from "../common/textArea";
const TicketToolTip = (props) => {
  const { user } = useContext(AuthContext);
  const { ticket } = props;
  const { t } = useTranslation();
  return (
    <div
      className="ticketstooltip"
      style={{
        width: "550px",
        minHeight: "280px",
      }}
    >
      <h5 className="text-primary">
        <u>{t("description")}</u>
      </h5>
      {/* <TextArea
        dir="rtl"
        onChange={handleChange}
        name="description"
        value={ticket.description}
        label="Description"
      /> */}
      {ticket.description !== null && (
        <span>
          <TextArea border={false} rows={2} value={ticket.description} />
        </span>
      )}
      {ticket.description === null && (
        <div className="text-danger">{t("tooltips.no_description")}</div>
      )}
      <span>
        <h5 className="text-primary">
          <u>{t("solution")}</u>
        </h5>
        {ticket.solution !== null && (
          <span>
            <TextArea border={false} rows={3} value={ticket.solution} />
          </span>
        )}

        {ticket.solution === null && (
          <div className="text-danger">{t("tooltips.no_solution")}</div>
        )}
      </span>
      {user.role === "employee" && (
        <React.Fragment>
          {" "}
          <h5 className="text-primary mt-2">
            <u>{t("notes")}</u>
          </h5>
          {ticket.notes !== null && (
            <span>
              <TextArea border={false} rows={2} value={ticket.notes} />
            </span>
          )}
          {ticket.notes === null && (
            <div className="text-danger">{t("tooltips.no_notes")}</div>
          )}
        </React.Fragment>
      )}
    </div>
  );
};

export default TicketToolTip;
