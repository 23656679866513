import React, { useContext, useEffect, useState } from "react";
import GetApiUrlContext from "../../context/GetApiUrlContext";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Navbar from "../navbar";
import useAxios from "../../utils/useAxios";
import Input from "../common/input";
import Button from "../common/button";
import ChangePassword from "../changePassword";
import CustomAlert from "../common/customAlert";
import useEmployeeInputs from "./useEmployeeInputs";

const EditEmployee = () => {
  const apiUrl = useContext(GetApiUrlContext);
  const params = useParams();
  const { t } = useTranslation();
  let axiosAuth = useAxios();
  const [showLogin, setShowLogin] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [employee, setEmployee] = useState({
    name: "",
    email: "",
    phone: "",
    address: "",
    city: "",
    country: "",
    postal_code: "",
    role_title: "",
  });

  const getEmployee = async () => {
    let response = await axiosAuth.get(
      `${apiUrl}/employees/${params.company_id}/${params.id}`
    );
    setEmployee(response.data);
  };

  const editEmployee = async (e) => {
    e.preventDefault();
    let connectionString = `${apiUrl}/employees/${params.company_id}/${params.id}`;
    let response = await axiosAuth.put(connectionString, employee);
    setShowAlert(true);
    console.log(response.data);
  };

  const handleChange = (e) => {
    setEmployee((values) => ({ ...values, [e.target.name]: e.target.value }));
  };

  const handleShowLogin = () => {
    setShowLogin((showLogin) => !showLogin);
  };

  useEffect(() => {
    getEmployee();
  }, []);

  return (
    <React.Fragment>
      <Navbar />
      <div className="container w-50 mx-auto mt-3">
        <h1 className="text-center  mt-3">{t("edit_show_employee")}</h1>
        <div>
          <Button
            onClick={handleShowLogin}
            id="changeLoginBtn"
            className="btn btn-outline-secondary"
            type="button"
            label={t("change_password")}
          />
        </div>
        &nbsp;
        {showLogin && (
          <ChangePassword
            company_id={params.company_id}
            email={employee.email}
          />
        )}
        <CustomAlert
          dialog={t("alerts.employee_updated")}
          show={showAlert}
          setShow={setShowAlert}
        />
        <form onSubmit={editEmployee}>
          <Input
            key={employee.email}
            clearfocused="false"
            type="email"
            name="email"
            value={employee.email || ""}
            onChange={handleChange}
          />
          {useEmployeeInputs().map((input) => (
            <Input
              key={input.id}
              {...input}
              clearfocused="false"
              value={employee[input.name] || ""}
              onChange={handleChange}
            />
          ))}

          <div className="text-center mt-3 d-grid gap-2">
            <Button
              id="editEmployee"
              className="btn btn-primary"
              type="submit"
              label={t("update")}
            />
          </div>
        </form>
      </div>
    </React.Fragment>
  );
};

export default EditEmployee;
