import React from "react";
import { AuthProvider } from "./context/AuthContext";
import { ApiUrlProvider } from "./context/GetApiUrlContext";
import { BrowserRouter as Router, Route } from "react-router-dom";
import PrivateRoute from "./utils/privateRoute";
import { ToastContainer } from "react-toastify";
import ForwoardTicket from "./components/tickets/forwoardTicket";
import Tickets from "./components/tickets/tickets";
import EditTicket from "./components/tickets/editTicket";
import CreateTicket from "./components/tickets/createTicket";
import LoginForm from "./components/loginForm";
import ChangePassword from "./components/changePassword";
import ChooseCompany from "./components/chooseCompany";
import Customers from "./components/customers/customers";
import TestMe from "./components/testme";
import "bootstrap/dist/js/bootstrap.js";
import "bootstrap/dist/css/bootstrap.css";
import "font-awesome/css/font-awesome.css";

import "flag-icon-css/css/flag-icons.min.css";
import "./App.css";
import EditCustomer from "./components/customers/editCustomer";
import EditEmployee from "./components/employees/editEmployee";
import Employees from "./components/employees/employees";
import TicketsEmployeesReport from "./components/reports/ticketsEmployeesReport";
import TicketsCompanyReport from "./components/reports/ticketsCompanyReport";
import TicketsCustomerReport from "./components/reports/ticketsCustomerReport";
import TicketsCustomersReport from "./components/reports/ticketsCustomersReport";

const App = () => {
  // let { pathname } = useLocation();
  return (
    <React.Fragment>
      <link rel="preconnect" href="https://fonts.gstatic.com"></link>
      <link
        href="https://fonts.googleapis.com/css2?family=Hanalei+Fill&display=swap"
        rel="stylesheet"
      ></link>
      <ToastContainer />
      <main className="container-fluid">
        <Router>
          <ApiUrlProvider>
            <AuthProvider>
              {/* {pathname !== "/tickets/createTicket" &&
              pathname !== "/login" &&
              pathname !== "/" && <Navbar />} */}
              <Route
                exact
                path="/tickets/createTicket/:company_id"
                component={CreateTicket}
              />
              {/* <PrivateRoute
              exact
              path="/tickets/:customer_id/:customer_name"
              component={Tickets}
            /> */}
              <PrivateRoute
                exact
                path="/tickets/editTicket/:company_id/:id"
                component={EditTicket}
              />
              <PrivateRoute exact path="/tickets" component={Tickets} />
              <PrivateRoute
                exact
                path="/tickets/ForwoardTicket/:company_id/:id"
                component={ForwoardTicket}
              />
              <PrivateRoute
                exact
                path="/choose_company"
                component={ChooseCompany}
              />
              <Route exact path="/" component={LoginForm} />
              <Route exact path="/login" component={LoginForm} />
              <Route
                exact
                path="/login/:company_hostname"
                component={LoginForm}
              />
              <Route exact path="/testme" component={TestMe} />
              <PrivateRoute
                exact
                path="/customers/editCustomer/:company_id/:id"
                component={EditCustomer}
              />
              <PrivateRoute exact path="/customers" component={Customers} />
              <PrivateRoute
                exact
                path="/employees/editEmployee/:company_id/:id"
                component={EditEmployee}
              />
              <PrivateRoute exact path="/employees" component={Employees} />
              <PrivateRoute
                exact
                path="/changepassword/"
                component={ChangePassword}
              />
              <PrivateRoute
                exact
                path="/tickets_cutomers_report"
                component={TicketsCustomersReport}
              />
              <PrivateRoute
                exact
                path="/tickets_customer_report"
                component={TicketsCustomerReport}
              />
              <PrivateRoute
                exact
                path="/tickets_company_report"
                component={TicketsCompanyReport}
              />
              <PrivateRoute
                exact
                path="/tickets_employees_report"
                component={TicketsEmployeesReport}
              />
            </AuthProvider>
          </ApiUrlProvider>
        </Router>
      </main>
    </React.Fragment>
  );
};

export default App;
