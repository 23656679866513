import React, { useState } from "react";
import { useTranslation } from "react-i18next";

const TestMe = () => {
  const [ticket, setTickate] = useState({ name: "", role: "" });

  const { t } = useTranslation();

  const handleClick = () => {
    setTickate({ name: "baruch", role: "dba" });
    console.log(ticket);
  };

  return (
    <div>
      <h2>{t("full_name")}</h2>
      <button
        onClick={() => {
          handleClick();
        }}
      >
        Click Me
      </button>
    </div>
  );
};

export default TestMe;
