import { useTranslation } from "react-i18next";

export default function useRegisterInputs() {
  const { t } = useTranslation();
  const register_inputs = [
    {
      id: "email",
      name: "email",
      type: "email",
      placeholder: t("email"),
      label: t("email"),
      errorMessage: t("errors.error_email"),
      required: true,
    },
    {
      id: "password",
      name: "password",
      type: "password",
      placeholder: t("password"),
      label: t("password"),
      errorMessage: t("errors.error_password"),
      pattern: "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$",
    },
    {
      id: "confirm_password",
      name: "confirm_password",
      type: "password",
      placeholder: t("confirm_password"),
      label: t("confirm_password"),
      errorMessage: t("errors.error_confirm_password"),
    },
  ];

  return register_inputs;
}
