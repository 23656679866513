import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import Table from "../common/table";
import Edit from ".././common/edit";
import ButtonIcon from "../common/buttonIcon";
import ConfirmAlert from "../common/confirmAlert";

const EmployeesTable = (props) => {
  const { employees, onSort, sortColumn, handleDelete } = props;
  const [showConfirm, setShowConfirm] = useState(false);
  const { t } = useTranslation();
  const history = useHistory();
  let currentComapny = JSON.parse(localStorage.getItem("currentCompany"));

  let columns = [
    { path: "name", label: t("input_name"), key: "name" },
    { path: "email", label: t("email"), key: "email" },
    { path: "phone", label: t("phone"), key: "phone" },
    {
      path: "options",
      label: t("options"),
      key: "options",
      content: (employee) => (
        <React.Fragment>
          <ConfirmAlert
            show={showConfirm}
            setShow={setShowConfirm}
            object_id={employee.id}
            handleClick={handleDelete}
          />
          <span>
            <Edit
              classes="fa fa-user-o"
              tippy_content={t("tooltips.go_to_employee")}
              path={`/employees/editEmployee/${currentComapny.id}/${employee.id}`}
            />
          </span>
          &nbsp;&nbsp;&nbsp;
          <span>
            <ButtonIcon
              classes="fa fa-minus-circle text-danger"
              tippy_content={t("tooltips.delete_employee")}
              onclick={() => {
                setShowConfirm(employee.id);
              }}
            />
          </span>
        </React.Fragment>
      ),
    },
  ];
  return (
    <Table
      columns={columns}
      data={employees}
      sortColumn={sortColumn}
      onSort={onSort}
    />
  );
};

export default EmployeesTable;
