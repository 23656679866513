import React from "react";

const Output = ({ name, label, value, error, ...rest }) => {
  if (value === null || value === undefined) {
    value = "Empty..";
  }
  return (
    <div className="form-group mb-3 text-center">
      <label htmlFor={name}>{label}</label>
      <output {...rest} name={name} id={name} className="form-control">
        {value}
      </output>
    </div>
  );
};

export default Output;
