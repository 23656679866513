import React from "react";
import { useTranslation } from "react-i18next";

export default function useCustomerInputs() {
  const { t } = useTranslation();
  const customer_inputs = [
    {
      id: "name",
      name: "name",
      type: "text",
      placeholder: t("input_name"),
      label: t("input_name"),
      errorMessage: t("errors.error_customer_name"),
      required: true,
      pattern: "^[א-תA-Za-z_ ]{3,16}$",
    },

    // {
    //   id: "password",
    //   name: "password",
    //   type: "password",
    //   placeholder: t("password"),
    //   label: t("password"),
    //   errorMessage: t("errors.error_password"),
    // },
    // {
    //   id: "confirm_password",
    //   name: "confirm_password",
    //   type: "password",
    //   placeholder: t("confirm_password"),
    //   label: t("confirm_password"),
    //   errorMessage: t("errors.error_confirm_password"),
    // },
    {
      id: "phone",
      name: "phone",
      type: "text",
      placeholder: t("phone"),
      label: t("phone"),
      errorMessage: t("errors.error_phone"),
      required: true,
      pattern: "^[0-9-]{7,11}$",
    },

    {
      id: "web",
      name: "web",
      type: "text",
      placeholder: t("web"),
      label: t("web"),
      errorMessage: t("errors.error_web"),
    },
    {
      id: "address",
      name: "address",
      type: "text",
      placeholder: t("address"),
      label: t("address"),
      errorMessage: t("errors.error_address"),
      required: true,
    },
    {
      id: "city",
      name: "city",
      type: "text",
      placeholder: t("city"),
      label: t("city"),
      errorMessage: t("errors.error_city"),
      required: true,
      pattern: "^[א-תA-Za-z_ ]{3,16}$",
    },
    {
      id: "country",
      name: "country",
      type: "text",
      placeholder: t("country"),
      label: t("country"),
      errorMessage: t("errors.error_country"),
      required: true,
      pattern: "^[א-תA-Za-z_ ]{3,16}$",
    },
    {
      id: "postal_code",
      name: "postal_code",
      type: "text",
      placeholder: t("postal_code"),
      label: t("postal_code"),
      errorMessage: t("errors.error_postal_code"),
    },
  ];

  return customer_inputs;
}
